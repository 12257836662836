import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACCOUNT_TYPES } from '../../const';
import { UserContext } from '../../contexts/UserContext';
import NewsContextProvider from '../../contexts/NewsContext';
import OrganizationProfile from './OrganizationProfile';
import VolunteerProfile from './VolunteerProfile';
import { MessageContext } from 'contexts/MessageContext';
import { ApprovalContext } from 'contexts/ApprovalContext';

const Profile: React.FC = () => {
    const navigate = useNavigate()
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const { accountType, isChecking, isLoggedIn } = useContext(UserContext);

    const { totalUnreadChats } = useContext(MessageContext);
    const { totalApprovalCount } = useContext(ApprovalContext);
    useEffect(() => {
        if (redirectToLogin) {
            navigate("/login");
        }
    }, [redirectToLogin, navigate]);
    return (
        <NewsContextProvider>
            {/* {redirectToLogin && navigate("/login")} */}
            {isChecking ? (
                <></>
            ) : (
                <>
                    {!isLoggedIn ? (
                        <>
                            {redirectToLogin === false &&
                                //setRedirectToLogin(true)}
                                (setRedirectToLogin(true),null)}
                        </>
                    ) : (
                        <>
                            {accountType &&
                            ACCOUNT_TYPES.indexOf(accountType) === 3 ? (
                                <OrganizationProfile
                                    totalUnreadChats={totalUnreadChats}
                                    totalApprovalCount={totalApprovalCount}
                                />
                            ) : (
                                <VolunteerProfile
                                    totalUnreadChats={totalUnreadChats}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </NewsContextProvider>
    );
};

export default Profile;
