import {
    Box,
    Button,
    CircularProgress,
    Container,
    Snackbar,
    TextField,
    Typography,
} from '@material-ui/core';

import React, { useEffect, useState, useCallback } from 'react';
import './HowItWorks.scss';
import { ProjectType } from 'types';
import FeaturedCard from 'components/FeaturedCard/FeaturedCard';
import CausesFilter from 'components/CausesFilter';
import { useLocation } from 'react-router-dom';

import useAlgolia from 'hooks/useAlgolia';
import { Alert, Autocomplete } from '@material-ui/lab';

export const VolunteerOpportunities = () => {
    const { projectsIndex, organizationsIndex } = useAlgolia();
    const location = useLocation();
    const initialSearch = location.state?.search ?? '';
    const initialCity = location.state?.city ?? '';
    const [projectSearch, setProjectSearch] = useState(initialSearch);
    const [citySearch, setCitySearch] = useState(initialCity);
    const [searchedProjects, setSearchedProjects] = useState<
        ProjectType[] | null
    >(null);
    const [isSearching, setIsSearching] = useState(true);
    const [isOnlineSelected, setIsOnlineSelected] = useState(false);
    const [isInPersonSelected, setIsInPersonSelected] = useState(false);
    const [isBothSelected, setIsBothSelected] = useState(false);
    const [causeFilter, setCauseFilter] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [cityOptions, setCityOptions] = useState<any>([]);
    const [selectedCity, setSelectedCity] = useState({
        value: (location?.state as any)?.city ?? undefined,
    });

    const searchProject = useCallback(async () => {
        setIsSearching(true);
        let filters = '';
        if (isOnlineSelected && !isInPersonSelected && !isBothSelected) {
            filters += 'activity_type=1';
        } else if (!isOnlineSelected && !isBothSelected && isInPersonSelected) {
            filters += 'activity_type=2';
        } else if (!isOnlineSelected && !isInPersonSelected && isBothSelected) {
            filters += '(activity_type=3)';
        } else if (!isOnlineSelected && isInPersonSelected && isBothSelected) {
            filters += '(activity_type=2 OR activity_type=3)';
        } else if (isOnlineSelected && isInPersonSelected && !isBothSelected) {
            filters += '(activity_type=1 OR activity_type=2)';
        } else if (isOnlineSelected && !isInPersonSelected && isBothSelected) {
            filters += '(activity_type=1 OR activity_type=2)';
        } else {
            filters += `(activity_type=1 OR activity_type=2 OR activity_type=3)`;
        }
        if (citySearch) {
            filters +=
                citySearch.trim() === ''
                    ? undefined
                    : ` AND city:"${citySearch}"`;
        }
        if (causeFilter !== '') filters += ' AND ' + causeFilter;
        const result = await projectsIndex.search(projectSearch, {
            optionalWords: [projectSearch],
            filters,
        });
        setSearchedProjects(result.hits as any);
        setIsSearching(false);
    }, [
        isOnlineSelected,
        isInPersonSelected,
        isBothSelected,
        causeFilter,
        projectSearch,
        projectsIndex,
        citySearch,
    ]);

    useEffect(() => {
        searchProject();
    }, [searchProject]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getCities = useCallback((search: string) => {
        organizationsIndex
            .searchForFacetValues('city', search)
            .then((result: any) => {
                setCityOptions(result.facetHits);
            });
    }, []);

    useEffect(() => {
        getCities(citySearch);
    }, [citySearch, getCities]);

    const handleCausesFilterChange = (selectedCauses: string) => {
        setCauseFilter(selectedCauses);
    };

    const handleCityAutoCompleteChange = (
        e: React.ChangeEvent<object>,
        data: any,
    ) => {
        setCitySearch(data?.value);
        setSelectedCity(data);
        !data && setCityOptions([]);
    };
    return (
        <div className="hwv_root">
            <Snackbar
                open={showSnackbar}
                autoHideDuration={4000}
                onClose={() => setShowSnackbar(false)}
            >
                <Alert
                    onClose={() => setShowSnackbar(false)}
                    severity="warning"
                >
                    Please Login to see all details of volunteers
                </Alert>
            </Snackbar>
            <div className="hwv_body g_wfull g_whitebg">
                <Container maxWidth="md">
                    <div className="hw_searcharea">
                        <div className="hw_searcharea_items">
                            <div className="hw_searcharea_input">
                                <TextField
                                    value={projectSearch}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => setProjectSearch(e.target.value)}
                                    fullWidth
                                    placeholder="Search by keyword"
                                    size="small"
                                    variant="outlined"
                                />
                            </div>
                            <div className="hw_searcharea_input">
                                <Autocomplete
                                    id="city-box-demo"
                                    options={cityOptions}
                                    value={selectedCity}
                                    onChange={handleCityAutoCompleteChange}
                                    getOptionLabel={(option: any) =>
                                        option.value || ''
                                    }
                                    renderOption={(option: any) => {
                                        return (
                                            <div
                                                className="hw_sc_suggestion"
                                                dangerouslySetInnerHTML={{
                                                    __html: option.highlighted,
                                                }}
                                            />
                                        );
                                    }}
                                    noOptionsText={'No city found'}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>,
                                            ) => setCitySearch(e.target.value)}
                                            label="Search by city"
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                            </div>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={searchProject}
                                className="g_primary_btn"
                            >
                                Search
                            </Button>
                        </div>
                    </div>
                    <div className="hw_divider">
                        <div className="hw_divider_line"></div>

                        <div className="hw_divider_text">
                            <Typography color="textSecondary" variant="h5">
                                Causes
                            </Typography>
                        </div>
                        <div className="hw_divider_line"></div>
                    </div>
                    <CausesFilter
                        onFilterChange={(selectedCauses) =>
                            handleCausesFilterChange(selectedCauses)
                        }
                    />
                    <div className="hw_divider">
                        <div className="hw_divider_line"></div>
                        <div className="hw_divider_text">
                            <Typography color="textSecondary" variant="h5">
                                Project Type
                            </Typography>
                        </div>
                        <div className="hw_divider_line"></div>
                    </div>

                    <div className="g_row g_mauto">
                        <div
                            className={
                                isOnlineSelected
                                    ? 'hw_selected_button'
                                    : 'hw_other_button'
                            }
                        >
                            <Button
                                fullWidth
                                // className={isOnlineSelected?"hw_selected_button":""}
                                variant={
                                    isOnlineSelected ? 'contained' : 'text'
                                }
                                color={
                                    isOnlineSelected ? 'secondary' : 'default'
                                }
                                className={
                                    isOnlineSelected ? 'g_primary_btn' : ''
                                }
                                onClick={() => {
                                    setIsOnlineSelected((pre) => !pre);
                                }}
                                disabled={isSearching}
                            >
                                Online
                            </Button>
                        </div>
                        <div
                            className={
                                isInPersonSelected
                                    ? 'hw_selected_button'
                                    : 'hw_other_button'
                            }
                        >
                            <Button
                                fullWidth
                                // className={isInPersonSelected?"hw_selected_button":""}
                                variant={
                                    isInPersonSelected ? 'contained' : 'text'
                                }
                                color={
                                    isInPersonSelected ? 'secondary' : 'default'
                                }
                                className={
                                    isInPersonSelected ? 'g_primary_btn' : ''
                                }
                                onClick={() => {
                                    setIsInPersonSelected((pre) => !pre);
                                }}
                                disabled={isSearching}
                            >
                                In-Person
                            </Button>
                        </div>
                        <div
                            className={
                                isInPersonSelected
                                    ? 'hw_selected_button'
                                    : 'hw_other_button'
                            }
                        >
                            <Button
                                fullWidth
                                // className={isInPersonSelected?"hw_selected_button":""}
                                variant={isBothSelected ? 'contained' : 'text'}
                                color={isBothSelected ? 'secondary' : 'default'}
                                className={
                                    isBothSelected ? 'g_primary_btn' : ''
                                }
                                onClick={() => {
                                    setIsBothSelected((pre) => !pre);
                                }}
                                disabled={isSearching}
                            >
                                Both
                            </Button>
                        </div>
                    </div>

                    <div className="hw_organization_list">
                        {isSearching && (
                            <Box mt={12} mb={12}>
                                <CircularProgress />
                            </Box>
                        )}
                        {!isSearching && searchedProjects?.length === 0 && (
                            <h4>No Project found!</h4>
                        )}
                        {!isSearching &&
                            searchedProjects?.map((item: ProjectType) => {
                                return (
                                    <FeaturedCard
                                        key={item.id + item.name}
                                        id={item.id}
                                        name={item.name}
                                        description={item.description}
                                        documentId={item?.objectID}
                                        //TODO: Need to update location address here
                                        location={item.location?.address?.value.description.toString()}
                                        image_url={item.image_url}
                                        skills={item.categories}
                                        requirements={item.requirements}
                                        variant="project"
                                    />
                                );
                            })}
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default VolunteerOpportunities;
