import { UserContext } from 'contexts/UserContext';
import { useContext, useEffect, useState } from 'react';
import { VolunteerProjectType } from 'types';
import firebase from 'utils/auth';

export default function useVolunteerPersonalProjects() {
    const { userData, isChecking } = useContext(UserContext);
    const [volunteerProjects, setVolunteerProjects] = useState<VolunteerProjectType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (isChecking || !userData) {
            console.log('User data is not ready or is still being checked.');
            return;
        }

        // Ensure userData.id exists before continuing
        if (!userData.id) {
            console.error('User ID is not defined.');
            return;
        }

        setIsLoading(true);
        const unsub = firebase.db
            .collection('volunteerProjects')
            .where('volunteer_id', '==', userData.id)
            .onSnapshot((snapshot) => {
                console.log('Snapshot received:', snapshot);
                if (!snapshot.empty) {
                    const projects: VolunteerProjectType[] = [];
                    snapshot.docs.forEach((doc) => {
                        console.log('Document data:', doc.data());  // Log the document data to inspect it
                        projects.push(doc.data() as VolunteerProjectType);
                    });
                    setVolunteerProjects(projects);
                } else {
                    console.log('No volunteer projects found.');
                    setVolunteerProjects([]);
                }
                setIsLoading(false);
            }, (error) => {
                console.error('Error fetching volunteer projects:', error);
                setIsLoading(false);
            });

        // Clean up the listener on unmount or when userData changes
        return unsub;
    }, [userData, isChecking]);

    // Log the projects and loading state for debugging purposes
    console.log('Volunteer Projects:', volunteerProjects);
    console.log('Is Loading:', isLoading);

    return { volunteerProjects, isLoading };
}
