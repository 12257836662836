import { capitalize } from 'lodash';

export const getSignupEmailBody = (applicant_name: string) => {
    return `<p>Hello ${applicant_name},</p>

    <p>We have received your application. We are excited that you would like to join our community. At N-Vest Partners, we are committed to leveraging our global network of academic institutions, corporate entities, and individuals to establish relationships that are sustainable and impactful.</p>

    <p>We are reviewing your application and will get back to you in 24-48 hours.</p>

    <p>Thank you,<br>
        N-Vest Partners</p>`;
};

export const getAdminSignupNotificationEmailBody = (
    applicant_name: string,
    contactInfo: string,
    application_type: string,
    emailAddress: string,
    aboutUser: Record<string, unknown>,
) => {
    const aboutUserEntries = Object.entries(aboutUser)
        .filter(([, value]) => value) // Filter out entries with falsy values
        .map(
            ([key, value]) =>
                `<li><strong>${capitalize(
                    key.replace(/_/g, ' '),
                )}:</strong> ${value}</li>`,
        )
        .join('');

    return `<p>Hello Admin,</p>

    <p>A new user, ${applicant_name}, has signed up for your application. Here are their application details:</p>

    <ul>
        <li><strong>Name:</strong> ${applicant_name}</li>
        <li><strong>Contact Info:</strong> ${contactInfo}</li>
        <li><strong>Application Type:</strong> ${application_type}</li>
        <li><strong>Email Address:</strong> ${emailAddress}</li>
        <ul>${aboutUserEntries}</ul>
    </ul>

    <p>Thank you,<br>
        Your Application Team</p>`;
};
