import { Box, Button, Modal, Typography } from '@material-ui/core';
import React, { FC, useState, useContext, useEffect, useCallback } from 'react';
import firebase from 'utils/auth';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import ProjectsList from './ProjectsList';
import ProjectSuggestion from './ProjectSuggestion';
import CreateAndUpdateVolunteerProject from './CreateAndUpdateVolunteerProject';
import { VolunteerProjectType } from 'types';
import VolunteerProjectsList from './VolunteerProjectsList';
import { VolunteerProjectsContext } from 'contexts/VolunteerProjectsContext';
import { UserContext } from 'contexts/UserContext';

// import './index.scss'
// export interface VolunteerProjectsProps {
//     titleVariant: 'volunteer';
//     volunteerProjectsData: Array<VolunteerProjectType>;
//     updateVolunteerProjectsData: () => void;
//     setLoading?: any;
// }
const VolunteerProjects = () => {
    const { volunteerProjects, isLoading } = useContext(
        VolunteerProjectsContext,
    );
    const { userData } = useContext(UserContext);
    console.log('volunteerProjects', volunteerProjects);
    const navigate = useNavigate();
    const [isCreateModalOpen, setIsCreateModelOpen] = useState(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [volunteerProjectsData, setVolunteerProjectsData] =
        useState<Array<VolunteerProjectType> | null>();
    const [selectedVolunteerProjects, setSelectedVolunteerProjects] =
        useState<VolunteerProjectType>();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const userId = firebase?.auth?.currentUser?.uid;
    useEffect(() => {
        setVolunteerProjectsData(volunteerProjects);
    }, [volunteerProjects, isLoading]);
    useEffect(() => {
        getVolunteerPersonalProjects()
    }, [userData]);
    const getVolunteerPersonalProjects= useCallback(async () => {
        let returnData = await firebase.getVolunteerPersonalProjects();
        if (returnData && returnData.status === 'ok') {
            setVolunteerProjectsData(returnData?.payload as []);
        } else {
            enqueueSnackbar('Error! Try refreshing the page.', {
                key: 'getVolunteerPersonalProjectsDataError',
                variant: 'error',
            });
            setVolunteerProjectsData([]);
        }
    }, [enqueueSnackbar]);
    const handleCreateProject = async (
        volunteerProject: VolunteerProjectType,
    ) => {
        console.log('volunteerProject', volunteerProject);
        let result: { status: string; payload: any } | null = {
            status: 'ok',
            payload: '',
        };
        setIsCreateModelOpen(false);
        setLoading(true);
        // props.setLoading(true);
        if (result && result.status === 'ok') {
            const returnData = await firebase.createVolunteerProject(
                volunteerProject,
                userId,
            );
            console.log('Return data:', returnData);

            if (returnData?.status === 'success') {
                enqueueSnackbar('Volunteer Project Created!', {
                    key: 'handleCreateVolunteerProjectSuccess',
                    variant: 'success',
                });
                // Optionally redirect to another page instead of reloading
                window.location.reload();
            } else {
                console.error(
                    'Error creating volunteer project:',
                    returnData?.payload,
                );
                enqueueSnackbar(
                    'Failed to create volunteer project! Try again.',
                    {
                        key: 'handleCreateVolunteerProjectError',
                        variant: 'error',
                    },
                );
            }
        }

        // props.updateVolunteerProjectsData();
        setLoading(false);
        // props.setLoading(false);
    };
    const handleUpdateProject = async (
        volunteerProject: VolunteerProjectType,
    ) => {
        console.log('volunteerProject:', volunteerProject);
        setIsCreateModelOpen(false);
        setLoading(true);

        const returnData = await firebase.updateVolunteerProject(
            volunteerProject,
        );
        console.log('Return data:', returnData);

        if (returnData.status === 'ok') {
            enqueueSnackbar('Project Updated!', {
                key: 'handleUpdateVolunteerProjectSuccess',
                variant: 'success',
            });
            window.location.reload();
        } else {
            enqueueSnackbar('Failed to update project! Try again.', {
                key: 'handleUpdateVolunteerProjectError',
                variant: 'error',
            });
        }

        setLoading(false);
    };

    const handleIsEdit = (data: VolunteerProjectType) => {
        console.log('inside the index.ts handleIsEdit and the data is', data);
        // setSelectedProjectsId(id)
        setSelectedVolunteerProjects(data);
        setIsCreateModelOpen(true);
        setIsEdit(true);
    };
    const handleDelete = (id: String) => {
        console.log('trying to delete the volunteer project');
    };
    return (
        <>
            <Box mt={6}>
                <Typography variant="h5" component="div">
                    <Box fontWeight={600} style={{ display: 'inline' }}>
                        Personal Projects
                    </Box>
                    <Button
                        className="pl_createBtn"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setSelectedVolunteerProjects(undefined);
                            setIsCreateModelOpen(true);
                            setIsEdit(false);
                        }}
                    >
                        Create
                    </Button>
                </Typography>
            </Box>
            <VolunteerProjectsList
                handleIsEdit={handleIsEdit}
                handleDelete={handleDelete}
                volunteerProjectsData={volunteerProjectsData || []}
                isLoading={isLoading}
            />
            <Box mt={6}>
                <Typography variant="h5" component="div">
                    <Box fontWeight={600} style={{ display: 'inline' }}>
                        My Projects
                    </Box>
                </Typography>
            </Box>
            <ProjectsList />
            <Box mt={4}>
                <Typography variant="h5" component="div">
                    <Box fontWeight={600}>Project Suggestions</Box>
                </Typography>
            </Box>
            <ProjectSuggestion />
            <Modal open={isCreateModalOpen} onClose={setIsCreateModelOpen}>
                <CreateAndUpdateVolunteerProject
                    isEdit={isEdit}
                    data={selectedVolunteerProjects}
                    onUpdate={handleUpdateProject}
                    onCreate={handleCreateProject}
                    onClose={setIsCreateModelOpen}
                />
            </Modal>
        </>
    );
};
export default VolunteerProjects;
