import React, { useState } from 'react';
import {
    Tabs,
    Tab,
    Box,
    createStyles,
    makeStyles,
    Theme,
    Grid,
} from '@material-ui/core';
import BroadCast from 'components/BroadCast/BroadCast';
import { Messages } from 'components/Messages/Messages';
import './Communication.scss';
import { NewsFeed } from 'components/NewsFeed/NewsFeed';
import Approval from 'components/Approval/Approval';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        communicationPanel: {
            color: '#000',
            fontColor: '#000',
            maxWidth: '80rem',
        },
    }),
);

const TabPanel: React.FC<TabPanelProps> = (props) => {
    const { children, value, index, className, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className={className}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
};

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
type CommunicationPropsType = {
    totalUnreadChats: number;
    totalApprovalCount: number;
};

const Communication: React.FC<CommunicationPropsType> = (
    props: CommunicationPropsType,
) => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (
        event: React.ChangeEvent<{}>,
        newValue: number,
    ) => {
        setSelectedTab(newValue);
    };

    return (
        <Grid container className="communication-container">
            <Grid item xs={3}>
                {/* Sidebar */}
                <Tabs
                    className="sidebar-tabs"
                    textColor="primary"
                    indicatorColor="primary"
                    orientation="vertical"
                    // variant="scrollable"
                    value={selectedTab}
                    onChange={handleTabChange}
                >
                    <Tab
                        label={`Messages${
                            props.totalUnreadChats > 0
                                ? ` (${props.totalUnreadChats})`
                                : ''
                        }`}
                        {...a11yProps(0)}
                    />
                    <Tab label="Broadcast" {...a11yProps(1)} />
                    <Tab label="Our Feed" {...a11yProps(2)} />
                    <Tab
                        label={`Approvals${
                            props.totalApprovalCount > 0
                                ? ` (${props.totalApprovalCount})`
                                : ''
                        }`}
                        {...a11yProps(3)}
                    />
                </Tabs>
            </Grid>
            {/* Content Area */}
            <Grid item xs={9}>
                <TabPanel
                    index={0}
                    value={selectedTab}
                    className={classes.communicationPanel}
                >
                    <Messages />
                </TabPanel>
                <TabPanel
                    index={1}
                    value={selectedTab}
                    className={classes.communicationPanel}
                >
                    <BroadCast />
                </TabPanel>
                <TabPanel
                    index={2}
                    value={selectedTab}
                    className={classes.communicationPanel}
                >
                    <NewsFeed />
                </TabPanel>
                <TabPanel
                    index={3}
                    value={selectedTab}
                    className={classes.communicationPanel}
                >
                    <Approval />
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default Communication;
