import React, { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
    Box,
    IconButton,
    Snackbar,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import './ProjectCard2.scss';
import { Location } from '../../types/index';
import LocationIcon from '@material-ui/icons/RoomTwoTone';
import Image from 'material-ui-image';
import firebase from 'utils/auth';
import { Edit, RestoreFromTrash } from '@material-ui/icons';
import { UserContext } from 'contexts/UserContext';
import { Alert } from '@material-ui/lab';
export interface ProjectCard2Props {
    match?: any;
    key: number;
    title: string;
    description: string;
    location: Location;
    image: string;
    map: string;
    projectId: string;
    onClick: Function;
    handleEditProject: Function;
    titleVariant: string;
    documentId?: string;
}
interface ParamTypes {
    id: string;
}
const ProjectCard2: FC<ProjectCard2Props> = (props: ProjectCard2Props) => {
    const {
        image,
        location,
        title,
        description,
        projectId,
        onClick,
        titleVariant,
        documentId,
        handleEditProject,
    } = props;
    const [showSnackbar, setShowSnackbar] = useState(false);
    const { userData, isLoggedIn } = useContext(UserContext);
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const handleShowDetails = async () => {
        if (isLoggedIn) {
            const returnData = await firebase.getProjectDetailsById(projectId);
            returnData && onClick(returnData);
        } else {
            setShowSnackbar(true);
        }
    };
    const handleEdit = async (e: any, projectId: string) => {
        e.preventDefault();
        e.stopPropagation();
        if (isLoggedIn) {
            const returnData = await firebase.getProjectDetailsById(
                titleVariant === 'volunteer' ? projectId : documentId!,
            );
            returnData && handleEditProject(returnData);
        } else {
            setShowSnackbar(true);
        }
    };

    const handleDelete = async (projectId: string) => {
        if (isLoggedIn) {
            const returnData = await firebase.deleteProject(
                titleVariant === 'volunteer' ? projectId : documentId!,
            );
            if (returnData?.status === 200) {
                enqueueSnackbar('Project Deleted!', {
                    key: 'handleDelete',
                    variant: 'success',
                });
                window.location.reload();
            } else {
                enqueueSnackbar('Failed to delete project! Try again.', {
                    key: 'handleDelete',
                    variant: 'error',
                });
            }
        } else {
            setShowSnackbar(true);
        }
    };
    const renderMarkers = (map: any, maps: any) => {
        let marker = new maps.Marker({
            position: {
                lat: location ? location.latitude : 0,
                lng: location ? location.longitude : 0,
            },
            map,
        });
        return marker;
    };
    return (
        <div className="">
            <Snackbar
                open={showSnackbar}
                autoHideDuration={4000}
                onClose={() => setShowSnackbar(false)}
            >
                <Alert
                    onClose={() => setShowSnackbar(false)}
                    severity="warning"
                >
                    Please Login to see full list of organizations
                </Alert>
            </Snackbar>
            <div className="project_card">
                <div className="project_card_image_cont">
                    <Image
                        cover
                        imageStyle={{ borderRadius: '.6rem' }}
                        src={image}
                        onClick={handleShowDetails}
                    />
                    {isLoggedIn &&
                        (!id || id === userData?.organization_id) &&
                        userData?.account_type === 'Organization' && (
                            <div className="action_container">
                                <Tooltip title="Edit">
                                    <IconButton
                                        onClick={(e) =>
                                            handleEdit(e, projectId)
                                        }
                                    >
                                        <Edit fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton
                                        onClick={() => handleDelete(projectId)}
                                    >
                                        <RestoreFromTrash color="error" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                </div>

                <div className="project_card_description">
                    <Box
                        className="project_title_desc_loc"
                        onClick={handleShowDetails}
                    >
                        <Typography noWrap variant="h6">
                            {title}
                        </Typography>
                        <Typography
                            style={{
                                maxHeight: '4rem',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                                overflow: 'hidden',
                            }}
                            variant="body2"
                        >
                            {description}
                        </Typography>
                        <div className="project_card_location g_mt1 g_mb1">
                            <LocationIcon className="two_shaded_icon" />
                            <Typography variant="body1">
                                {location?.address?.label}
                            </Typography>
                        </div>
                        {/* <Button
                            className="g_primary_btn"
                            variant="contained"
                            color="secondary"
                            onClick={handleShowDetails}
                        >
                            Learn More
                        </Button> */}
                    </Box>
                </div>

                <div style={{ height: '30vh', width: '70%' }}>
                    <GoogleMapReact
                        yesIWantToUseGoogleMapApiInternals={true}
                        bootstrapURLKeys={{
                            key: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
                        }}
                        defaultCenter={{
                            lat: location ? location.latitude : 0,
                            lng: location ? location.longitude : 0,
                        }}
                        defaultZoom={15}
                        onGoogleApiLoaded={({ map, maps }) => {
                            return renderMarkers(map, maps);
                        }}
                    ></GoogleMapReact>
                </div>
            </div>
        </div>
    );
};

export default ProjectCard2;
