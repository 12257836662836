import React, { useContext } from 'react';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    makeStyles,
    Typography,
    Link,
} from '@material-ui/core';
import { UserType } from 'types';
import { useNavigate } from 'react-router-dom';
import { CommonDataContext } from 'contexts/CommonDataContext';

interface VolunteerSuggestionCardType {
    user: UserType;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '280px',
        margin: 'auto',
    },
    content: {
        display: 'flex',
        // height: '20rem',
        flexDirection: 'column',
    },
    skills_sectiom: {
        maxHeight: '5rem',
        overflowY: 'auto',
    },
}));

export default function VolunteerSuggestionCard({
    user,
}: VolunteerSuggestionCardType) {
    const classes = useStyles();
    const navigate = useNavigate();
    const { volunteerTimings } = useContext(CommonDataContext);

    return (
        <>
            <Card className={classes.root} variant="outlined">
                <CardContent className={classes.content}>
                    <Box display="flex" alignItems="center" gridGap="1rem">
                        <Avatar src={user.avatar} />
                        <Link
                            component="button"
                            variant="h6"
                            onClick={() => {
                                navigate(
                                    `/volunteer-profile-page/${user.objectID}`,
                                );
                            }}
                        >
                            <Typography>{`${user.first_name} ${user.last_name}`}</Typography>
                        </Link>
                    </Box>
                    <Box>
                        <Typography component="div">
                            <Box mt={1} mb={0.4} fontWeight={600}>
                                Skills
                            </Box>
                        </Typography>
                        <Typography
                            className={classes.skills_sectiom}
                            variant="subtitle2"
                        >
                            {user.skills && user.skills.length === 0
                                ? '(No Skill)'
                                : user.skills?.join(', ')}
                        </Typography>
                        <Typography component="div">
                            <Box mt={1} mb={0.4} fontWeight={600}>
                                Availability
                            </Box>
                        </Typography>
                        <Typography variant="subtitle2">
                            {volunteerTimings &&
                                volunteerTimings.find(
                                    (item: any) => item.id === user.timing,
                                )?.name}
                        </Typography>
                        <Typography component="div">
                            <Box mt={1} mb={0.4} fontWeight={600}>
                                Interested in
                            </Box>
                        </Typography>
                        <Typography variant="subtitle2">
                            {user.work_type === 1
                                ? 'Remote'
                                : user.work_type === 2
                                ? 'In-Person'
                                : 'Remote and In-Person'}{' '}
                            work
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
}
