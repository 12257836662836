import {
    Button,
    Checkbox,
    IconButton,
    ListItemText,
    makeStyles,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import React, {
    ChangeEvent,
    useState,
    useRef,
    useEffect,
    useCallback,
} from 'react';
import { Cancel } from '@material-ui/icons';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { ProjectType } from 'types';
import firebase from 'utils/auth';
import { useSnackbar } from 'notistack';
import GoogleLocation from '../AutocompleteLocation/Location';
import { Autocomplete } from '@material-ui/lab';
import './CustomStyle.scss';
import DatePickerCalendar from 'components/DatePicker/DatePickerCalender';
interface UpdateProjectProps {
    city: string;
    country: string;
    description: string;
    startDate: string;
    endDate: string;
    skills: Array<string>;
    requirements: Array<string>;
    imageUrl: string;
    projectName: string;
    groupName: string;
    onCreate: (project: ProjectType, imageFile: File | null) => void;
    onUpdate: (project: ProjectType, imageFile: File | null) => void;
    onClose: Function;
    data: ProjectType | undefined;
    isEdit: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '700px',
        maxHeight: '100vh',
        background: 'white',
        // borderRadius: '.6rem',
        padding: '1rem',
        boxSizing: 'border-box',
        overflowY: 'scroll',
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            margin: '1rem auto',
            maxHeight: '90vh',
        },
    },
    center: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    inputSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '49%',
        },
    },
    inputSectionFull: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
    },
    imageContainer: {
        maxWidth: '280px',
        maxHeight: '400px',
        '& img': {
            width: '100%',
        },
    },
    title: {
        fontSize: '1.1rem',
        fontWeight: 700,
        marginTop: '2rem',
        marginBottom: '.4rem',
    },
    helperText: {
        fontSize: '.8rem',
        color: 'gray',
        marginTop: '0',
        marginBottom: '.4rem',
    },
    uploadBtn: {
        fontSize: '1.1rem',
        fontWeight: 700,
        width: '10rem',
        height: '2rem',
        border: '.1rem solid gray',
        backgroundColor: 'lightgray',
    },
    buttons: {
        marginTop: '2rem',
        marginBottom: '1rem',
    },
    requirement: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& p': {
            margin: 0,
        },
    },
    selectRoot: {
        maxHeight: '2.5rem',
    },
    customInput: {
        width: '100%',
        cursor: 'pointer',
        '& .MuiInputBase-root': {
          backgroundColor: '#ffffff',
        },
      },
    dialogPaper: {
    borderRadius: '12px',
    overflow: 'hidden',
    border: 'none',
    },
    dialogHeader: {
    padding: '16px',
    backgroundColor: '#E97341', /* Orange background */
    textAlign: 'center',
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
    },
    dialogContent: {
    padding: '0px 16px 20px 16px',
    backgroundColor: 'white',
    },
    dialogActions: {
    justifyContent: 'space-between',
    backgroundColor: '#E97341', /* Orange background for footer */
    padding: '10px 16px',
    },
    actionButton: {
    color: 'white',
    fontWeight: 'bold',
    },
    cancelButton: {
    color: '#FFC4A3', /* Light orange for Cancel */
    },
}));

const UpdateProject = (props: UpdateProjectProps) => {
    const classes = useStyles();

    const { data, isEdit } = props;
    const [projectName, setProjectName] = useState<string>(data?.name || '');
    const [totalPartners, setTotalPartners] = useState<number | null>(data?.total_partners ?? null);
    const [address, setAddress] = useState<any>(data?.location?.address);
    const [description, setDescription] = useState(data?.description || '');
    const [startDate, setStartDate] = useState(
        data?.schedule_start || new Date().toISOString(),
    );
    const [endDate, setEndDate] = useState(
        data?.schedule_end || new Date().toISOString(),
    );
    const [projectDeadline, setProjectDeadline] = useState(
        data?.project_deadline || new Date().toISOString(),
    );//2024-11-29T08:28:56.000Z
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(new Date())
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(new Date())
    const [selectedProjectDeadline, setSelectedProjectDeadline] = useState<Date | null>(new Date())
    const [selectedField, setSelectedField] = useState<string | null>(null);
    const [open, setOpen] = useState(false);
    const [requirements, setRequirements] = useState(data?.requirements || []);
    const [groupName, setGroupName] = useState(data?.group_name || '');
    const [requirementText, setRequirementText] = useState('');
    const [causes, setCauses] = useState<string[]>(data?.causes || []);
    const [categories, setCategories] = useState<string[]>(
        data?.categories || [],
    );
    const [image, setImage] = useState<File | null>(null);
    const [type, setType] = useState<number>(data?.activity_type || 1);
    const [workPerWeek, setWorkPerWeek] = useState<number>(
        data?.activity_duration_per_week || 1,
    );
    const [causesList, setCausesList] = useState<string[] | null>(null);
    const [categoriesList, setCategoriesList] = useState<string[] | null>(null);
    const imageRef = useRef<any>();
    const fileInputRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();
    //setting start and end date
    useEffect(() => {
        if (data?.schedule_start) {
            setSelectedStartDate(new Date(data.schedule_start)); // Use setter to update state
        }
        if (data?.schedule_end) {
            setSelectedEndDate(new Date(data.schedule_end)); // Use setter to update state
        }
        if (data?.project_deadline) {
            setSelectedProjectDeadline(new Date(data.project_deadline)); // Use setter to update state
        }
    }, [data]);
    const setImageFile = (file: File | undefined) => {
        if (file) {
            let fr = new FileReader();
            fr.onload = function () {
                imageRef.current.src = fr.result;
            };
            fr.readAsDataURL(file);
            setImage(file);
        }
    };

    const fetchListData = useCallback(async () => {
        const causes = await firebase.getAllCauses();
        setCausesList(causes as []);
        const categories = await firebase.getAllCategories();
        setCategoriesList(categories as []);
    }, []);

    useEffect(() => {
        fetchListData();
    }, [fetchListData]);

    const getGeoCodeByAddress = async (location: any) => {
        try {
            const results = await geocodeByAddress(location);
            const { lat, lng } = await getLatLng(results[0]);
            const coordinates = { lat, lng };
            return coordinates;
        } catch (err) {}
        return location;
    };

    const handleSubmit = async () => {
        if (projectName.trim() === '') {
            enqueueSnackbar('Please mention a project name.', {
                variant: 'error',
            });
        }else if (totalPartners === 0) {
            enqueueSnackbar('Please mention a total partners.', {
                variant: 'error',
            });
        } else if (description?.trim() === '') {
            enqueueSnackbar('Please describe your project.', {
                variant: 'error',
            });
        } else if (groupName.trim() === '') {
            enqueueSnackbar('Please mention a group name.', {
                variant: 'error',
            });
        } else if (startDate.trim() === '') {
            enqueueSnackbar('Please mention a start date.', {
                variant: 'error',
            });
        } else if (endDate.trim() === '') {
            enqueueSnackbar('Please mention a end date.', {
                variant: 'error',
            });
        }else if (projectDeadline.trim() === '') {
            enqueueSnackbar('Please mention a project deadline date.', {
                variant: 'error',
            });
        } else if (requirements?.length === 0) {
            enqueueSnackbar('Please mention atleast one requirement.', {
                variant: 'error',
            });
        } else if (causes.length === 0) {
            enqueueSnackbar('Please mention atleast one cause.', {
                variant: 'error',
            });
        } else if (categories.length === 0) {
            enqueueSnackbar('Please mention atleast one category.', {
                variant: 'error',
            });
        } else if (!data?.image_url && image === null) {
            enqueueSnackbar('Please upload an image.', {
                variant: 'error',
            });
        } else {
            const locationCoordinates = address?.label
                ?.split(',')
                ?.slice(0, 1)
                .toString();
            const location = await getGeoCodeByAddress(locationCoordinates);

            isEdit
                ? props.onUpdate(
                      {
                          id: data?.id || '',
                          name: projectName,
                          total_partners: totalPartners ?? 0,
                          committed_partners: data?.committed_partners || 0,
                          description: description!,
                          group_name: groupName,
                          schedule_start: startDate,
                          schedule_end: endDate,
                          project_deadline: projectDeadline,
                          organization_id: firebase.auth.currentUser?.uid ?? '',
                          team_members: [],
                          team_member_ids: [],
                          map_url: '',
                          image_url: data?.image_url ? data.image_url : '',
                          skills: [],
                          requirements: requirements?.length
                              ? requirements
                              : [],
                          activity_type: type,
                          activity_duration_per_week: workPerWeek,
                          causes: causes,
                          categories: categories,
                          location: {
                              address,
                              latitude: location.lat,
                              longitude: location.lng,
                          },
                      },
                      image,
                  )
                : props.onCreate(
                      {
                          id: '',
                          name: projectName,
                          total_partners: totalPartners ?? 0,
                          committed_partners: data?.committed_partners || 0,
                          description: description!,
                          group_name: groupName,
                          schedule_start: startDate,
                          schedule_end: endDate,
                          project_deadline: projectDeadline,
                          organization_id: firebase.auth.currentUser?.uid ?? '',
                          team_members: [],
                          team_member_ids: [],
                          map_url: '',
                          image_url: '',
                          skills: [],
                          requirements: requirements?.length
                              ? requirements
                              : [],
                          activity_type: type,
                          activity_duration_per_week: workPerWeek,
                          causes: causes,
                          categories: categories,
                          location: {
                              address,
                              latitude: location.lat,
                              longitude: location.lng,
                          },
                      },
                      image,
                  );
        }
    };

    const handleSelectChange = (
        event: React.ChangeEvent<{}>,
        value: string[],
    ) => {
        setCauses(value);
    };
    const handleSkillSelectChange = (
        event: React.ChangeEvent<{}>,
        value: string[],
    ) => {
        setCategories(value);
    };
   
  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      const isoDate = date.toISOString(); // Convert to ISO format
      console.log("ISO Date:", isoDate);
      console.log("date:", date);
      setStartDate(isoDate); // Set ISO string
      setSelectedStartDate(date); // Set ISO string
    } else {
        setStartDate(null); // Handle null case
        setSelectedStartDate(null); // Handle null case
    }
    handleClose();
  }; 
  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const isoDate = date.toISOString(); // Convert to ISO format
      console.log("ISO Date:", isoDate);
      console.log("date:", date);
      setEndDate(isoDate); // Set ISO string
      setSelectedEndDate(date); // Set ISO string
    } else {
        setEndDate(null); // Handle null case
        setSelectedEndDate(null); // Handle null case
    }
    handleClose();
  };
  const handleProjectDeadlineChange = (date: Date | null) => {
    if (date) {
      const isoDate = date.toISOString(); // Convert to ISO format
      console.log("ISO Date:", isoDate);
      console.log("date:", date);
      setProjectDeadline(isoDate); // Set ISO string
      setSelectedProjectDeadline(date); // Set ISO string
    } else {
        setProjectDeadline(null); // Handle null case
        setSelectedProjectDeadline(null); // Handle null case
    }
    handleClose();
  };
  const handleOpen = (field: string) => {
    setSelectedField(field);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false); 
  };

    return (
        <div className={classes.root}>
            <div className={classes.row}>
                <div className={classes.inputSectionFull}>
                    <p className={classes.title}>Project Title</p>
                    <TextField
                        value={projectName}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setProjectName(e.target.value)
                        }
                    />
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.inputSectionFull}>
                    <p className={classes.title}>Project Image</p>
                    <div className={classes.center}>
                        <div className={classes.imageContainer}>
                            <img ref={imageRef} src={data?.image_url} alt="" />
                        </div>
                        <label>
                            <input
                                ref={fileInputRef}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setImageFile(e.target.files?.[0])
                                }
                                style={{ display: 'none' }}
                                type="file"
                            />
                            <Button
                                onClick={() => fileInputRef.current.click()}
                                variant="contained"
                                color="primary"
                            >
                                upload image
                            </Button>
                        </label>
                    </div>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.inputSection}>
                    <p className={classes.title}>Commitment Type</p>
                    <Select
                        value={type}
                        onChange={(event: React.ChangeEvent<any>) => {
                            setType(event.target.value);
                        }}
                        fullWidth
                        variant="outlined"
                        className={classes.selectRoot}
                    >
                        <MenuItem value={1}>Online</MenuItem>
                        <MenuItem value={2}>In-Person</MenuItem>
                        <MenuItem value={3}>Both</MenuItem>
                    </Select>
                </div>
                <div className={classes.inputSection}>
                    <p className={classes.title}>Commitment Hours</p>

                    <Select
                        value={workPerWeek}
                        onChange={(event: React.ChangeEvent<any>) => {
                            setWorkPerWeek(event.target.value);
                        }}
                        fullWidth
                        variant="outlined"
                        className={classes.selectRoot}
                    >
                        <MenuItem value={1}>1-2 hours/week</MenuItem>
                        <MenuItem value={2}>3-4 hours/week</MenuItem>
                        <MenuItem value={3}>5-6 hours/week</MenuItem>
                        <MenuItem value={4}>7 Hours+/week</MenuItem>
                    </Select>
                </div>
            </div>
            <div >
                {/* Start Date */}
                <div>
                    <p
                    style={{
                        fontSize: '1.1rem',
                        fontWeight: 700,
                        marginTop: '2rem',
                        marginBottom: '.4rem',
                    }}
                    >Start Date</p>
                    <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onClick={() => handleOpen("start")} // Open the dialog for Start Date
                    value={selectedStartDate ? selectedStartDate.toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" }) : ""}
                    placeholder="Select a start date"
                    InputProps={{ readOnly: true }}
                    style={{ cursor: "pointer", backgroundColor: "#fff" }}
                    />
                </div>
                {/* End Date */}
                <div>
                    <p style={{
                        fontSize: '1.1rem',
                        fontWeight: 700,
                        marginTop: '2rem',
                        marginBottom: '.4rem',
                    }}>End Date</p>
                    <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onClick={() => handleOpen("end")} // Open the dialog for End Date
                    value={selectedEndDate ? selectedEndDate.toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" }) : ""}
                    placeholder="Select an end date"
                    InputProps={{ readOnly: true }}
                    style={{ cursor: "pointer", backgroundColor: "#fff" }}
                    />
                </div>
                {/* Project Deadline */}
                <div>
                    <p style={{
                        fontSize: '1.1rem',
                        fontWeight: 700,
                        marginTop: '2rem',
                        marginBottom: '.4rem',
                    }}>Project Deadline</p>
                    <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onClick={() => handleOpen("deadline")} // Open the dialog for Project Deadline
                    value={selectedProjectDeadline ? selectedProjectDeadline.toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" }) : ""}
                    placeholder="Select a project deadline"
                    InputProps={{ readOnly: true }}
                    style={{ cursor: "pointer", backgroundColor: "#fff" }}
                    />
                </div>
                <DatePickerCalendar
                        handleClose={handleClose}
                        selected={
                        selectedField === "start"
                            ? selectedStartDate
                            : selectedField === "end"
                            ? selectedEndDate
                            : selectedProjectDeadline
                        }
                        handleChange={
                        selectedField === "start"
                            ? handleStartDateChange
                            : selectedField === "end"
                            ? handleEndDateChange
                            : handleProjectDeadlineChange
                        } 
                        minDate={
                            selectedField === "start"
                              ? new Date("2010-01-01")
                              : selectedField === "end"
                              ? selectedStartDate || undefined
                              : undefined
                        }
                        maxDate={
                            selectedField === "deadline"
                              ? selectedStartDate || undefined
                              : undefined
                        }
                        open={open}
                        />
                {selectedStartDate!==null && selectedField === "end" && selectedEndDate && selectedEndDate < selectedStartDate && (
                    <p className="error-message">End date should not be before Start date!</p>
                )}

                {/* {selectedField === "deadline" && selectedProjectDeadline && selectedProjectDeadline > new Date() && (
                    <p className="error-message">Project deadline cannot be in the future!</p>
                )} */}
            </div>
            <div className={classes.row}>
                <div className={classes.inputSectionFull}>
                    <p className={classes.title}>Total Partners</p>
                    <TextField
                        value={totalPartners}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setTotalPartners(Number(e.target.value) || 0)
                        }
                        type="number"
                        inputProps={{min:0}}
                    />
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.inputSectionFull}>
                    <p className={classes.title}>Cause</p>
                    <p className={classes.helperText}>
                        This will also help volenteers find this project
                    </p>

                    <Autocomplete
                        multiple
                        value={causes}
                        onChange={handleSelectChange}
                        disableCloseOnSelect
                        options={causesList ?? []}
                        getOptionLabel={(option) => option}
                        fullWidth
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox checked={selected} />
                                <ListItemText primary={option} />
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Causes"
                                placeholder="Select Causes"
                                size="small"
                            />
                        )}
                    />
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.inputSectionFull}>
                    <p className={classes.title}>Skills/Categories</p>
                    <p className={classes.helperText}>
                        Please select the skills required to complete this
                        project. This will also help volenteers find this
                        project.
                    </p>
                    <Autocomplete
                        multiple
                        value={categories}
                        onChange={handleSkillSelectChange}
                        disableCloseOnSelect
                        options={categoriesList ?? []}
                        fullWidth
                        getOptionLabel={(option) => option}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox checked={selected} />
                                <ListItemText primary={option} />
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Skills/Categories"
                                placeholder="Select Skills/Categories"
                                size="small"
                            />
                        )}
                    />
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.inputSectionFull}>
                    <p className={classes.title}>Group Name</p>
                    <p className={classes.helperText}>
                        what do you want to call the group that is working on
                        this project? (i.e. Nature Wellfare Group)
                    </p>
                    <TextField
                        value={groupName}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setGroupName(e.target.value)
                        }
                    />
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.inputSectionFull}>
                    <p className={classes.title}>Location</p>

                    <GoogleLocation
                        selectProps={{
                            isClearable: true,
                            value: address,
                            onChange: (val: any) => {
                                setAddress(val);
                            },
                            placeholder: 'Location',
                        }}
                    />
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.inputSectionFull}>
                    <p className={classes.title}>Project Overview</p>
                    <TextField
                        value={description}
                        variant="outlined"
                        size="small"
                        fullWidth
                        multiline
                        minRows={5}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setDescription(e.target.value)
                        }
                    />
                </div>
            </div>

            <div className={classes.row}>
                <div className={classes.inputSectionFull}>
                    <p className={classes.title}>Experience Required</p>
                    <p className={classes.helperText}>
                        Keep the requirements concise and to the point.
                    </p>
                    {requirements?.map((requirement, index) => (
                        <div key={index} className={classes.requirement}>
                            <IconButton
                                onClick={() =>
                                    setRequirements((pre) => {
                                        let newArray = [...pre!];
                                        newArray.splice(index, 1);
                                        return newArray;
                                    })
                                }
                            >
                                <Cancel />
                            </IconButton>
                            <p>{requirement}</p>
                        </div>
                    ))}
                    <TextField
                        value={requirementText}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setRequirementText(e.target.value)
                        }
                        InputProps={{
                            endAdornment: (
                                <Button
                                    size="small"
                                    onClick={() => {
                                        setRequirements((pre) => [
                                            ...pre,
                                            requirementText,
                                        ]);
                                        setRequirementText('');
                                    }}
                                >
                                    Add
                                </Button>
                            ),
                        }}
                    />
                </div>
            </div>

            <div className={`${classes.row} ${classes.buttons}`}>
                <div className={classes.inputSection}>
                    <Button
                        fullWidth
                        variant="outlined"
                        color={'primary'}
                        onClick={() => props.onClose(false)}
                    >
                        Cancel
                    </Button>
                </div>
                <div className={classes.inputSection}>
                    <Button
                        fullWidth
                        variant="contained"
                        color={'primary'}
                        onClick={handleSubmit}
                    >
                        {isEdit ? 'Update' : 'Create'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

UpdateProject.defaultProps = {
    city: '',
    country: '',
    description: '',
    startDate: '2021-01-01',
    endDate: '2021-01-01',
    skills: [],
    requirements: [],
    imageUrl: '',
    projectName: '',
    groupName: '',
};

export default UpdateProject;
