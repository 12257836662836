import React, { FC, useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import VolunteerProjectCard from 'components/ProjectCard/VolunteerProjectCard';
import { VolunteerProjectType } from 'types';
interface VolunteerProjectsListProps {
    handleIsEdit?: Function;
    handleDelete?: Function;
    volunteerProjectsData: Array<VolunteerProjectType>;
    isLoading: Boolean;
}
const VolunteerProjectsList: FC<VolunteerProjectsListProps> = (
    props: VolunteerProjectsListProps,
) => {
    const { handleIsEdit, handleDelete, volunteerProjectsData, isLoading } =
        props;
    return (
        <Box mt="2rem">
            {!isLoading && volunteerProjectsData.length === 0 ? (
                <Typography>
                    You have no personal projects. Create a project to get
                    started!
                </Typography>
            ) : null}
            {!isLoading && volunteerProjectsData.length > 0
                ? volunteerProjectsData.map(
                      (project: VolunteerProjectType, index: number) => (
                          <VolunteerProjectCard
                              key={index}
                              documentId={project.documentId}
                              projectId={project.id}
                              heading={project.partnershipOpportunity}
                              subHeading={project.causes.join(', ')}
                              startTime={project.schedule_start}
                              endTime={project.schedule_end}
                              duration={project.activity_duration_per_week}
                              handleIsEdit={handleIsEdit}
                              handleDelete={handleDelete}
                              volunteerLocation={project.activity_type}
                              volunteerSelectedProject={project}
                          />
                      ),
                  )
                : null}
        </Box>
    );
};
export default VolunteerProjectsList;
