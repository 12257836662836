import React, { useContext, useState, ChangeEvent, FormEvent } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import './Signup.scss';
import BasicInfoForm from '../../components/BasicInfoForm/BasicInfoForm';
import References from '../../components/References/References';
import Statement from '../../components/Statement/Statement';
import validator from 'validator';
// import signupImg from '../../images/signup1.jpg';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { SignupContext } from '../../contexts/SignupContext';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { validateEmail } from '../../utils/InputValidator';
import firebase from '../../utils/auth';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { ACCOUNT_TYPES } from '../../const';
import { Fade, Modal } from '@material-ui/core';
import { AppRoutes } from 'App';
import AboutOrganization from 'components/AboutOrganization/AboutOrganization';
// import SignupEmail from '../../components/Email/SignupEmail';
import {
    getAdminSignupNotificationEmailBody,
    getSignupEmailBody,
} from './email';
const isStrongPassword = (password: string): boolean => {
    return validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
    });
};
const Alert = (props: any) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Signup: React.FC = () => {
    const [snackData, setSnackData] = React.useState({
        show: false,
        type: 'success',
        msg: '',
    });

    const [showModal, setShowModal] = React.useState(false);
    const [showSuccessModal, setShowSuccessModal] = React.useState(false);

    const navigate = useNavigate();

    const {
        step1Data,
        step2Data,
        step3Data,
        step2File,
        isUploadingData,
        setIsUploadingData,
        step3DataReferences,
    } = useContext(SignupContext);

    const handleSnackClose = (event: any, reason: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackData((pre: any) => ({
            ...pre,
            show: false,
        }));
    };

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: '100%',
            },
            backButton: {
                marginRight: theme.spacing(1),
            },
            instructions: {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
        }),
    );

    const getApplicationType = () => {
        return step1Data.application_type === 'Individual Partners';
    };

    function getSteps() {
        const defaultSteps = [
            'Step 1: Basic Information',
            'Step 2: About Your Organization',
        ];

        if (getApplicationType()) {
            defaultSteps[1] = 'Step 2: Profession & Supporting Information';
            defaultSteps.push('Step 3: Professional References');
        }

        return defaultSteps;
    }

    function getStepContent(stepIndex: number) {
        switch (stepIndex) {
            case 0:
                return <BasicInfoForm />;
            case 1:
                if (getApplicationType()) {
                    return <References />;
                }
                return <AboutOrganization />;
            case 2:
                return <Statement />;
            default:
                return 'Unknown stepIndex';
        }
    }

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = async () => {
        // const emailHtml = render(<SignupEmail url={'https://google.com'} />);
        // ${step1Data.first_name} ${step1Data.last_name}
        if (activeStep === 0) {
            if (step1Data.application_type === null) {
                setSnackData({
                    show: true,
                    msg: 'Please select an application type.',
                    type: 'warning',
                });
            } else if (
                step1Data.application_type !== ACCOUNT_TYPES[0] &&
                step1Data.company_name === ''
            ) {
                setSnackData({
                    show: true,
                    msg: 'Please enter company name.',
                    type: 'warning',
                });
            } else if (step1Data.first_name.trim() === '') {
                setSnackData({
                    show: true,
                    msg: 'Please enter first name.',
                    type: 'warning',
                });
            } else if (step1Data.last_name.trim() === '') {
                setSnackData({
                    show: true,
                    msg: 'Please enter last name.',
                    type: 'warning',
                });
            } else if (
                step1Data.application_type === ACCOUNT_TYPES[0] &&
                step1Data.date_time === ''
            ) {
                setSnackData({
                    show: true,
                    msg: 'Please enter your date of birth.',
                    type: 'warning',
                });
            } else if (step1Data.gender === '') {
                setSnackData({
                    show: true,
                    msg: 'Please select a gender.',
                    type: 'warning',
                });
            } else if (step1Data.phone_number.trim() === '') {
                setSnackData({
                    show: true,
                    msg: 'Please enter your phone number.',
                    type: 'warning',
                });
            } else if (step1Data.email === '') {
                setSnackData({
                    show: true,
                    msg: 'Please enter your email.',
                    type: 'warning',
                });
            } else if (!validateEmail(step1Data.email)) {
                setSnackData({
                    show: true,
                    msg: 'Please enter a valid email.',
                    type: 'warning',
                });
            } else if (step1Data.password === '') {
                setSnackData({
                    show: true,
                    msg: 'Please enter a unique password.',
                    type: 'warning',
                });
            } else if (!isStrongPassword(step1Data.password)) {
                setSnackData({
                    show: true,
                    msg: 'Password must be at least 8 characters long, include uppercase, lowercase, a number, and a special character.',
                    type: 'warning',
                });
            } else if (step1Data.password !== step1Data.conf_password) {
                setSnackData({
                    show: true,
                    msg: 'Passwords do not match.',
                    type: 'warning',
                });
            } else if (
                step1Data.application_type !== ACCOUNT_TYPES[0] &&
                step1Data.city.trim() === ''
            ) {
                setSnackData({
                    show: true,
                    msg: 'Please enter a city.',
                    type: 'warning',
                });
            } else if (
                step1Data.application_type !== ACCOUNT_TYPES[0] &&
                step1Data.country.trim() === ''
            ) {
                setSnackData({
                    show: true,
                    msg: 'Please enter a country.',
                    type: 'warning',
                });
            } else if (!step1Data.agreed) {
                setSnackData({
                    show: true,
                    msg: 'You must agree to our terms and conditions.',
                    type: 'warning',
                });
            } else {
                setActiveStep(1);
            }
        } else if (activeStep === 1) {
            let hasError = false;

            if (getApplicationType()) {
                if (step2Data.profession === '') {
                    setSnackData({
                        show: true,
                        msg: 'Reference ' + ': Enter Profession.',
                        type: 'warning',
                    });
                    hasError = true;
                } else if (step2Data.linkedin_profile === '') {
                    setSnackData({
                        show: true,
                        msg: 'Reference ' + ': Enter phone number.',
                        type: 'warning',
                    });
                    hasError = true;
                }
            } else if (step2Data.about_organization === '') {
                setSnackData({
                    show: true,
                    msg: 'Reference ' + ': Enter About Organization',
                    type: 'warning',
                });
                hasError = true;
            } else if (step2Data.about_organization.length > 1000) {
                setSnackData({
                    show: true,
                    msg:
                        'Reference ' +
                        ': About your Organization should be less than 1000 characters',
                    type: 'warning',
                });
                hasError = true;
            } else if (step2Data.mission_and_goals === '') {
                setSnackData({
                    show: true,
                    msg: 'Reference ' + ': Enter Mission and Goals',
                    type: 'warning',
                });
                hasError = true;
            } else if (step2Data.mission_and_goals.length > 1000) {
                setSnackData({
                    show: true,
                    msg:
                        'Reference ' +
                        ': Mission and goals should be less than 1000 characters',
                    type: 'warning',
                });
                hasError = true;
            } else if (step2Data.website_link === '') {
                setSnackData({
                    show: true,
                    msg: 'Reference ' + ': Enter Website link',
                    type: 'warning',
                });
                hasError = true;
            }

            if (!hasError) {
                if (getApplicationType()) {
                    setActiveStep(2);
                } else {
                    setShowModal(true);
                }
            }
        } else if (getApplicationType() && activeStep === 2) {
            if (step3DataReferences.length) {
                let hasError = false;
                for (let i = 0; i < step3DataReferences.length; i++) {
                    if (step3DataReferences[i].full_name === '') {
                        setSnackData({
                            show: true,
                            msg: 'Reference ' + ': Enter name.',
                            type: 'warning',
                        });
                        hasError = true;
                    } else if (step3DataReferences[i].relationship === '') {
                        setSnackData({
                            show: true,
                            msg: 'Reference ' + ': Enter relationship.',
                            type: 'warning',
                        });
                        hasError = true;
                    } else if (step3DataReferences[i].email === '') {
                        setSnackData({
                            show: true,
                            msg: 'Reference ' + ': Enter email.',
                            type: 'warning',
                        });
                        hasError = true;
                    } else if (!validateEmail(step3DataReferences[i].email)) {
                        setSnackData({
                            show: true,
                            msg: 'Reference ' + ': Invalid email.',
                            type: 'warning',
                        });
                        hasError = true;
                    } else if (step3DataReferences[i].phone_number === '') {
                        setSnackData({
                            show: true,
                            msg: 'Reference ' + ': Enter phone number.',
                            type: 'warning',
                        });
                        hasError = true;
                    }

                    if (!hasError) {
                        setShowModal(true);
                    }
                }
            } else {
                setShowModal(true);
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleModalClose = () => {
        // do nothing...
    };

    const submitAplication = () => {
        setIsUploadingData(true);
        firebase.registerUser(
            step1Data.email,
            step1Data.password,
            async (data: any) => {
                if (data.status === 'ok') {
                    let url = null;
                    if (step2File) {
                        url = await firebase.uploadFileToStorage(
                            data.user.uid,
                            step2File,
                        );
                    }

                    await firebase.uploadUserDataToFirestore(
                        data.user.uid,
                        step1Data,
                        step2Data,
                        step3Data,
                        step3DataReferences,
                        url,
                    );
                    setIsUploadingData(false);
                    setShowModal(false);
                    setShowSuccessModal(true);
                    await firebase.logout();

                    await firebase.sendEmail({
                        subject:
                            'Thank you for applying to join N-Vest Partners',
                        html: getSignupEmailBody(
                            `${step1Data.first_name} ${step1Data.last_name}`,
                        ),
                        to: step1Data.email,
                    });

                    //Send an email notification to the administrator.
                    await firebase.sendEmail({
                        subject:
                            'A new user has signed up to join N-Vest Partners',
                        html: getAdminSignupNotificationEmailBody(
                            `${step1Data.first_name} ${step1Data.last_name}`,
                            step1Data.phone_number,
                            step1Data.application_type,
                            step1Data.email,
                            step2Data,
                        ),
                        to: 'info@nvestpartners.org@gmail.com',
                        // to: 'info@nvestpartners.org@gmail.com',
                    });
                } else if (data.status === 'auth/email-already-in-use') {
                    setSnackData({
                        show: true,
                        msg: 'Email is already registered!',
                        type: 'error',
                    });
                    setIsUploadingData(false);
                } else {
                    console.log('REGISTRATION ERROR: ');
                    console.log(data);
                    setSnackData({
                        show: true,
                        msg: 'Unknown Issue!',
                        type: 'error',
                    });
                    setIsUploadingData(false);
                }
            },
        );
    };

    const exitApplication = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setShowModal(false);
    };

    return (
        <Container
            className="root g_mt4"
            style={{
                padding: '0',
                paddingBottom: '50px',
                marginTop: 0,
                maxWidth: '100%',
            }}
        >
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="signup_modal"
                open={showModal}
                onClose={handleModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showModal}>
                    <div className="signup_modal_root">
                        <h2 id="transition-modal-title">
                            Are you sure you are ready to submit your
                            application?
                        </h2>
                        <div className="signup_modal_buttons">
                            <Button
                                variant="contained"
                                component="label"
                                color="secondary"
                                onClick={submitAplication}
                            >
                                Submit
                            </Button>
                            <br />
                            <br />
                            {/* <button className="signup_modal_exit" onClick={exitApplication}>Cancel</button> */}
                            <a
                                // eslint-disable-next-line
                                href="JavaScript:void(0);"
                                onClick={exitApplication}
                            >
                                Cancel
                            </a>
                        </div>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="signup_modal"
                open={showSuccessModal}
                onClose={handleModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showSuccessModal}>
                    <div className="signup_modal_root">
                        <h2 id="transition-modal-title">
                            Thank you for your application. We will review it
                            within 48-72 hours
                        </h2>
                        <div className="signup_modal_buttons">
                            <button
                                className="signup_modal_submit"
                                onClick={() => {
                                    navigate(AppRoutes.Landing);
                                }}
                            >
                                Back to Homepage
                            </button>
                        </div>
                    </div>
                </Fade>
            </Modal>
            <Snackbar
                open={snackData.show}
                autoHideDuration={4000}
                onClose={handleSnackClose}
            >
                <Alert onClose={handleSnackClose} severity={snackData.type}>
                    {snackData.msg}
                </Alert>
            </Snackbar>
            <Backdrop open={isUploadingData} style={{ zIndex: 9999999 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={classes.root}>
                <div className="signup_container"></div>
                <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    style={{ maxWidth: '1280px', margin: '0 auto' }}
                >
                    {step1Data.application_type &&
                        steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                </Stepper>
                <div>
                    {activeStep === steps.length ? (
                        <div>
                            <h3 className={classes.instructions}>
                                Thanks for Applying <br />
                                Keep an eye on your email inbox — you’ll be
                                hearing from us soon. <br />
                                What’s Next?
                            </h3>
                            <h3>
                                {' '}
                                1 . A member from our team will be reaching out
                                shortly after <br />
                                reviewing your application to schedule <br /> a
                                1-on-1 video follow-up
                            </h3>
                            <h3>
                                2. Once we determine if you're the right fit we
                                will be sending <br />
                                you a welcome packet along with some basic visa,
                                medical and emergency <br />
                                contact forms for you to fill out for our
                                records.
                            </h3>
                            <div className="cta-container">
                                <Button
                                    className="projectpage"
                                    onClick={handleReset}
                                >
                                    {' '}
                                    Back to project page
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className={classes.instructions}>
                                {getStepContent(activeStep)}
                            </div>
                            <div className="cta-container">
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.backButton}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                >
                                    {activeStep === steps.length - 1
                                        ? 'Finish'
                                        : 'Next'}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
};
export default Signup;
