import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import { CommonDataContext } from '../../contexts/CommonDataContext';
import { UserContext } from '../../contexts/UserContext';
import EditIcon from '@material-ui/icons/Edit';
import firebase from 'utils/auth';

import './MyInformation.scss';
import EditDialog from './EditDialog';
import { useSnackbar } from 'notistack';
import ProfilePictureUpdator from './ProfilePictureUpdator';
import {
    Autocomplete,
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
} from '@material-ui/lab';

type Nullable<T> = T | null;
type SkillValue = { value: string; label: string };

export const MyInformation = () => {
    const [updatingName, setUpdatingName] = useState(false);
    const [updatingCompany, setUpdatingCompany] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [updatingEmail, setUpdatingEmail] = useState(false);
    const [updatingPassword, setUpdatingPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [skills, setSkills] = useState<Nullable<SkillValue[]>>(null);

    const { userData } = useContext(UserContext);
    const [aboutMe, setAboutMe] = useState(userData?.about_me? userData.about_me:'');
    const {
        // approvedCompanyNames,
        volunteerLocations,
        volunteerSkills,
        volunteerTimings,
        mobileViewSm,
    } = useContext(CommonDataContext);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (userData?.skills) {
            setSkills(
                userData?.skills.map((value: string) => ({
                    value,
                    label: value,
                })),
            );
        }
    }, [userData?.skills]);

    const handleUpdateName = (firstName: string, lastName: string) => {
        setUpdatingName(false);
        setIsLoading(true);
        firebase.updateUsername(firstName, lastName);
        setIsLoading(false);
    };
    const handleUpdateAboutMe = () => {
        setIsLoading(true);
        firebase.updateAboutMe(aboutMe);
        setIsLoading(false);
    };

    const handleUpdateCompany = (company: string) => {
        setUpdatingCompany(false);
        setIsLoading(true);
        firebase.updateCompany({ company });
        setIsLoading(false);
        setAboutMe('');
    };

    // const handleUpdateAddress = (address: string) => {
    //     setUpdatingAddress(false);
    //     setIsLoading(true);
    //     firebase.updateCompany({ location: { address: { label: address } } });
    //     setIsLoading(false);
    // };

    const handleUpdateEmail = async (email: string, password: string) => {
        setUpdatingEmail(false);
        setIsLoading(true);
        let error = await firebase.updateEmail(email, password);
        setIsLoading(false);
        if (error) {
            if (error === 'auth/invalid-email') {
                enqueueSnackbar('Invalid Email!', {
                    key: error,
                    variant: 'error',
                });
            } else if (error === 'auth/email-already-in-use') {
                enqueueSnackbar('Email is already used by another account!', {
                    key: error,
                    variant: 'error',
                });
            } else {
                enqueueSnackbar('Invalid Password!', {
                    key: error,
                    variant: 'error',
                });
            }
        }
    };

    const handleUpdateAvatar = async (file: File) => {
        setIsLoading(true);
        let error = await firebase.updateAvatar(file);
        setIsLoading(false);
        if (error) {
            enqueueSnackbar('Network Issue!', {
                key: error as any,
                variant: 'error',
            });
        }
    };

    const handleUpdatePassword = async (
        newPassword: string,
        oldPassword: string,
    ) => {
        setUpdatingPassword(false);
        setIsLoading(true);
        let error = await firebase.updatePassword(newPassword, oldPassword);
        setIsLoading(false);
        if (error) {
            if (error === 'auth/weak-password') {
                enqueueSnackbar('Please choose a strong password!', {
                    key: error,
                    variant: 'error',
                });
            } else {
                enqueueSnackbar('Invalid Password!', {
                    key: error,
                    variant: 'error',
                });
            }
        }
    };

    const updateWorkType = async (newWorkTypeId: number) => {
        setIsLoading(true);
        await firebase.updateWorkType(newWorkTypeId);
        setIsLoading(false);
    };

    const updateWorkTiming = async (newWorkTimingId: number) => {
        setIsLoading(true);
        await firebase.updateWorkTiming(newWorkTimingId);
        setIsLoading(false);
    };

    const addSkill = async (skill: string) => {
        setIsLoading(true);
        await firebase.addSkill(skill);
        setIsLoading(false);
    };

    const removeSkill = async (skill: string) => {
        setIsLoading(true);
        await firebase.removeSkill(skill);
        setIsLoading(false);
    };

    const handleChange = (
        e: React.ChangeEvent<object>,
        data: any,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<any>,
    ) => {
        if (reason === 'remove-option') {
            details && removeSkill(details.option.value);
        } else {
            details && addSkill(details.option.value);
        }
        setSkills(data);
    };

    const handleAboutMeChange = async (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const aboutMe = e.target.value;
        setAboutMe(e.target.value);
        // Clear the previous timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        // Set a new timeout
        const id = setTimeout(() => {
            firebase.updateAboutMe(aboutMe);
        }, 500);

        setTimeoutId(id);
    };

    return (
        <div className="g_wfull">
            {!userData ? (
                <CircularProgress />
            ) : (
                <>
                    <Backdrop style={{ zIndex: 9999999 }} open={isLoading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <EditDialog
                        type="double"
                        title="Update Username"
                        heading="First Name:"
                        heading2="Last Name:"
                        isPassword={false}
                        isOpen={updatingName}
                        onClose={() => setUpdatingName(false)}
                        onUpdate={handleUpdateName}
                        initialValue={userData.first_name}
                        initialValue2={userData.last_name}
                    />
                    <EditDialog
                        type="simple"
                        title="Update Company"
                        heading="Company Name:"
                        isPassword={false}
                        isOpen={updatingCompany}
                        onClose={() => setUpdatingCompany(false)}
                        onUpdate={handleUpdateCompany}
                        initialValue={userData.company}
                    />
                    {/* <EditDialog
                        type="simple"
                        title="Update Address"
                        heading="Address:"
                        isPassword={false}
                        isOpen={updatingAddress}
                        onClose={() => setUpdatingAddress(false)}
                        onUpdate={handleUpdateAddress}
                        initialValue={userData.company}
                    /> */}
                    <EditDialog
                        type="double"
                        title="Update Email"
                        heading="New Email:"
                        heading2="Password:"
                        isPassword={false}
                        isPassword2={true}
                        isOpen={updatingEmail}
                        onClose={() => setUpdatingEmail(false)}
                        onUpdate={handleUpdateEmail}
                        initialValue={userData.email}
                    />
                    <EditDialog
                        type="double"
                        title="Update Password"
                        heading="New Password:"
                        heading2="Old Password:"
                        isPassword={true}
                        isPassword2={true}
                        isOpen={updatingPassword}
                        onClose={() => setUpdatingPassword(false)}
                        onUpdate={handleUpdatePassword}
                        initialValue={''}
                    />
                    <div className="g_row g_tspacer3 g_bspacer2">
                        <Typography variant="h5" component="div">
                            <Box fontWeight={600}>Profile Information</Box>
                        </Typography>
                    </div>
                    <div className="mi_avatarContainer">
                        <ProfilePictureUpdator
                            img={userData.avatar}
                            handleUpdateAvatar={handleUpdateAvatar}
                        />
                    </div>
                    <div className="mi_textRow">
                        <div className="mi_smallOnMobile">
                            <TextField
                                disabled
                                InputProps={{
                                    endAdornment: mobileViewSm ? (
                                        <IconButton
                                            size={'small'}
                                            onClick={() =>
                                                setUpdatingName(true)
                                            }
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    ) : null,
                                }}
                                placeholder="Name"
                                variant="outlined"
                                size="small"
                                value={
                                    userData.first_name +
                                    ' ' +
                                    userData.last_name
                                }
                                fullWidth
                            />
                        </div>
                        {!mobileViewSm && (
                            <Button
                                size="medium"
                                onClick={() => setUpdatingName(true)}
                            >
                                <u>Update Name</u>
                                {/* <Typography variant="body2">Update Name</Typography> */}
                            </Button>
                        )}
                    </div>
                    <div className="mi_textRow">
                        <div className="mi_smallOnMobile">
                            <TextField
                                disabled
                                InputProps={{
                                    endAdornment: mobileViewSm ? (
                                        <IconButton
                                            size={'small'}
                                            onClick={() =>
                                                setUpdatingCompany(true)
                                            }
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    ) : null,
                                }}
                                placeholder="Company"
                                variant="outlined"
                                size="small"
                                value={userData.company}
                                style={{ width: '100%' }}
                            />
                        </div>
                        {!mobileViewSm && (
                            <Button
                                size="medium"
                                onClick={() => setUpdatingCompany(true)}
                            >
                                <u>Update Company</u>
                                {/* <Typography variant="body2">Update Company</Typography> */}
                            </Button>
                        )}
                    </div>
                    {/* <div className="mi_textRow">
                       <div className="mi_smallOnMobile">
                            <TextField
                                disabled
                                InputProps={{
                                    endAdornment: mobileViewSm ? (
                                        <IconButton
                                            size={'small'}
                                            onClick={() =>
                                                setUpdatingAddress(true)
                                            }
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    ) : null,
                                }}
                                placeholder="Address"
                                variant="outlined"
                                size="small"
                                value={userData.company}
                                style={{ width: '100%' }}
                            />
                        </div>
                        {!mobileViewSm && (
                            <Button
                                size="medium"
                                onClick={() => setUpdatingAddress(true)}
                            >
                                <u>Update Address</u>
                            </Button>
                        )}
                    </div> */}
                    <div className="mi_textRow">
                        <div className="mi_smallOnMobile">
                            <TextField
                                disabled
                                InputProps={{
                                    endAdornment: mobileViewSm ? (
                                        <IconButton
                                            size={'small'}
                                            onClick={() =>
                                                setUpdatingEmail(true)
                                            }
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    ) : null,
                                }}
                                placeholder="Email"
                                variant="outlined"
                                size="small"
                                value={userData.email}
                                fullWidth
                            />
                        </div>
                        {!mobileViewSm && (
                            <Button
                                className="mi_textRowBtn"
                                size="medium"
                                onClick={() => setUpdatingEmail(true)}
                            >
                                <u>Update Email</u>
                                {/* <Typography variant="body2">Update Email</Typography> */}
                            </Button>
                        )}
                    </div>
                    <div className="mi_textRow">
                        <div className="mi_smallOnMobile">
                            <TextField
                                disabled
                                type="password"
                                InputProps={{
                                    endAdornment: mobileViewSm ? (
                                        <IconButton
                                            size={'small'}
                                            onClick={() =>
                                                setUpdatingPassword(true)
                                            }
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    ) : null,
                                }}
                                placeholder="Password"
                                variant="outlined"
                                size="small"
                                value="******"
                                fullWidth
                            />
                        </div>
                        {!mobileViewSm && (
                            <Button
                                onClick={() => setUpdatingPassword(true)}
                                size="medium"
                            >
                                <u>Update Password</u>
                                {/* <Typography variant="body2">Update Password</Typography> */}
                            </Button>
                        )}
                    </div>
                    <div className="g_row g_tspacer3 g_bspacer2 g_wfull" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h5" component="div">
                            <Box fontWeight={600}>About Me</Box>
                        </Typography>
                        <Button
                            color="primary"
                            variant="contained"
                            size="medium"
                            disabled={aboutMe===userData.about_me?true:false}
                            onClick={handleUpdateAboutMe}
                        >
                            Update
                        </Button>
                    </div>
                    <div className="g_row g_tspacer3 g_bspacer2">
                        <TextField
                            multiline
                            minRows={9}
                            variant="outlined"
                            fullWidth
                            onChange={(e)=>{setAboutMe(e.target.value)}}
                            value={aboutMe}
                        />
                    </div>

                    <div className="g_row g_tspacer3 g_bspacer2 g_wfull">
                        <Typography variant="h5" component="div">
                            <Box fontWeight={600}>My Preferences</Box>
                        </Typography>
                    </div>
                    <div className="g_row g_mt2 g_mb1">
                        <Typography variant="body1">
                            I am interested in volunteer opportunities that are:
                        </Typography>
                    </div>
                    <div className="g_row mi_wrap_content">
                        {!volunteerLocations ? (
                            <CircularProgress />
                        ) : (
                            <>
                                {volunteerLocations.map(
                                    (
                                        item: { id: number; name: string },
                                        index: number,
                                    ) => (
                                        <div
                                            key={index}
                                            className="g_mr1 g_mb1"
                                        >
                                            <Button
                                                onClick={() =>
                                                    updateWorkType(item.id)
                                                }
                                                color="primary"
                                                variant={
                                                    item.id ===
                                                    userData.work_type
                                                        ? 'contained'
                                                        : 'outlined'
                                                }
                                            >
                                                {item.name}
                                            </Button>
                                        </div>
                                    ),
                                )}
                            </>
                        )}
                    </div>
                    <div className="g_row g_mt2 g_mb1 ">
                        <Typography variant="body1">
                            I am able to volunteer:
                        </Typography>
                    </div>
                    <div className="g_row mi_wrap_content">
                        {!volunteerTimings ? (
                            <CircularProgress />
                        ) : (
                            <>
                                {volunteerTimings.map(
                                    (
                                        item: { id: number; name: string },
                                        index: number,
                                    ) => (
                                        <div
                                            key={index}
                                            className="g_mr1 g_mb1"
                                        >
                                            <Button
                                                onClick={() =>
                                                    updateWorkTiming(item.id)
                                                }
                                                color="primary"
                                                variant={
                                                    item.id === userData.timing
                                                        ? 'contained'
                                                        : 'outlined'
                                                }
                                            >
                                                {item.name}
                                            </Button>
                                        </div>
                                    ),
                                )}
                            </>
                        )}
                    </div>
                    <div className="g_row g_mt2 g_mb1">
                        <Typography variant="body1">
                            I am interested in volunteer opportunities that are
                            related to following skills/categories:
                        </Typography>
                    </div>
                    <div className="g_row mi_wrap_content">
                        {!volunteerSkills ? (
                            <CircularProgress />
                        ) : (
                            <div className="mi_searcharea_input">
                                <Autocomplete
                                    multiple
                                    id="volunteer-skills"
                                    value={(skills as []) || []}
                                    options={volunteerSkills.map(
                                        (value: string) => ({
                                            value,
                                            label: value,
                                        }),
                                    )}
                                    getOptionLabel={(option: any) => {
                                        return option.value || '';
                                    }}
                                    getOptionSelected={(
                                        option: any,
                                        value: any,
                                    ) => option.value === value.value}
                                    renderOption={(option: any) => {
                                        return (
                                            <Typography noWrap>
                                                {option.label}
                                            </Typography>
                                        );
                                    }}
                                    noOptionsText={'No keyword found'}
                                    onChange={handleChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Skills"
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                    limitTags={3}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
