import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import './References.scss';
import { SignupContext } from '../../contexts/SignupContext';
import { InputLabel, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props: any) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const References: React.FC = () => {
    const { step2Data, setStep2Data, setStep2File } = useContext(SignupContext);
    console.log('🚀 ~ step2Data:', step2Data);
    const [fileName, setFileName] = useState('No file is selected.');
    const [snackData, setSnackData] = useState({
        show: false,
        type: 'warning',
        msg: '',
    });

    // const addReference = () => {
    //     setStep2Data((pre: any) => [
    //         ...pre,
    //         {
    //             profession: '',
    //             license_number: '',
    //             email: '',
    //             linkedin_profile: '',
    //             password: '',
    //         },
    //     ]);
    // };

    // const removeReference = () => {
    //     setStep2Data((pre: any) => {
    //         if (pre.length > 1) {
    //             let temp = [...pre];
    //             temp.pop();
    //             return temp;
    //         } else {
    //             return pre;
    //         }
    //     });
    // };

    const valueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setStep2Data((pre: any) => {
            return {
                ...pre,
                [event.target.name]: event.target.value,
            };
        });
    };

    const fileAdded = (event: any) => {
        let file = event.target.files[0];
        if (
            file.name.endsWith('.pdf') ||
            file.name.endsWith('.doc') ||
            file.name.endsWith('.docx') ||
            file.name.endsWith('.png') ||
            file.name.endsWith('.jpg') ||
            file.name.endsWith('.jpeg')
        ) {
            setSnackData({
                show: true,
                type: 'success',
                msg: 'File selected!',
            });
            setFileName(file.name);
            //TODO: Need to check
            // const name =
            //     'new-name-here' +
            //     file.name.substring(file.name.lastIndexOf('.'));
            file = new File([file], Date.now() + '-' + file.name, {
                type: file.type,
            });
            setStep2File(file);
        } else {
            setSnackData({
                show: true,
                type: 'warning',
                msg: 'File type not supported!',
            });
        }
    };

    const handleSnackClose = (event: any, reason: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackData((pre: any) => ({
            ...pre,
            show: false,
        }));
    };

    return (
        <Container className="root">
            <Snackbar
                open={snackData.show}
                autoHideDuration={4000}
                onClose={handleSnackClose}
            >
                <Alert onClose={handleSnackClose} severity={snackData.type}>
                    {snackData.msg}
                </Alert>
            </Snackbar>
            <div>
                <form noValidate autoComplete="off">
                    <div>
                        <div className="row">
                            <div className="column">
                                <InputLabel style={{ paddingLeft: '10px' }}>
                                    Please indicate your profession
                                </InputLabel>
                                <TextField
                                    id="fullName"
                                    name={'profession'}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => valueChanged(e)}
                                    value={step2Data.profession}
                                    type="text"
                                    label="Profession*"
                                    variant="outlined"
                                    className="form-field width-auto"
                                />
                            </div>
                            <div className="column">
                                <InputLabel
                                    style={{
                                        paddingLeft: '10px',
                                    }}
                                >
                                    Please provide a link to your LinkedIn
                                    profile
                                </InputLabel>

                                <TextField
                                    required
                                    id="phoneNumber"
                                    label="LinkedIn profile"
                                    variant="outlined"
                                    className="form-field width-auto"
                                    name={'linkedin_profile'}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => valueChanged(e)}
                                    value={step2Data.linkedin_profile}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div style={{ width: '100%' }}>
                                <div className="full-width column">
                                    <InputLabel
                                        style={{
                                            paddingLeft: '10px',
                                        }}
                                    >
                                        Please provide a professional license
                                        number if your profession provides it so
                                        that we can verify
                                    </InputLabel>

                                    <TextField
                                        id="license_number"
                                        label="License number"
                                        variant="outlined"
                                        className="form-field width-auto"
                                        style={{ width: '45%' }}
                                        type="text"
                                        name={'license_number'}
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>,
                                        ) => valueChanged(e)}
                                        value={step2Data.license_number}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="cta-container">
                            <p className="file_name">{fileName}</p>
                        </div>
                        <div className="cta-container">
                            {/* <Button variant="contained" color="secondary">
              Upload
            </Button> */}
                            <Button
                                variant="contained"
                                component="label"
                                color="secondary"
                            >
                                Upload Resume
                                <input
                                    type="file"
                                    hidden
                                    onChange={fileAdded}
                                />
                            </Button>
                        </div>
                        {/* <div className="row">
                                        <div className="full-width"></div>
                                    </div> */}
                    </div>

                    {/* <div className="ref-buttons">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={removeReference}
                        >
                            - Remove Reference
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={addReference}
                        >
                            + Add Reference
                        </Button>
                    </div> */}
                    {/* <div className="cta-container">
            <Button variant="contained" color="secondary">
              Save & Continue
            </Button>
          </div> */}
                </form>
            </div>
        </Container>
    );
};
export default References;
