import React, { FC, useState } from 'react';
import firebase from 'utils/auth';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Modal, Typography } from '@material-ui/core';
import { ProjectType } from '../../types';
import ProjectCard2 from '../ProjectCard2/ProjectCard2';
import UpdateProject from 'components/UpdateProject/UpdateProject';
import './ProjectsList.scss';
export interface ProjectsListProps {
    titleVariant: 'volunteer' | 'organization';
    projectsData: Array<ProjectType>;
    updateProjectsData: () => void;
    setLoading?: any;
}

const ProjectsList: FC<ProjectsListProps> = (props: ProjectsListProps) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [projectData, setProjectData] = useState<ProjectType>();

    const [isCreateModalOpen, setIsCreateModelOpen] = useState(false);

    const handleCreateProject = async (
        project: ProjectType,
        imageFile: File | null,
    ) => {
        let result: { status: string; payload: any } | null = {
            status: 'ok',
            payload: '',
        };
        setIsCreateModelOpen(false);
        props.setLoading(true);
        if (imageFile !== null) {
            result = await firebase.createProjectImage(imageFile);
        }
        if (result && result.status === 'ok') {
            project.image_url = result.payload as string;
            let returnData = await firebase.createProject(project);
            if (returnData.status === 'ok') {
                enqueueSnackbar('Project Created!', {
                    key: 'handleCreateProjectSuccess',
                    variant: 'success',
                });
                window.location.reload();
            } else {
                enqueueSnackbar('Failed to create project! Try again.', {
                    key: 'handleCreateProjectError',
                    variant: 'error',
                });
            }
        }
        props.updateProjectsData();
        props.setLoading(false);
    };

    const handleUpdateProject = async (
        project: ProjectType,
        imageFile: File | null,
    ) => {
        let result: { status: string; payload: any } | null = {
            status: 'ok',
            payload: '',
        };
        setIsCreateModelOpen(false);
        props.setLoading(true);
        if (imageFile !== null) {
            result = await firebase.updateProjectImage(imageFile);
        } else if (project.image_url) {
            result.payload = project.image_url;
        }
        if (result && result.status === 'ok') {
            project.image_url = result.payload as string;
            let returnData = await firebase.updateProject(project);
            if (returnData.status === 'ok') {
                enqueueSnackbar('Project Updated!', {
                    key: 'handleUpdateProjectSuccess',
                    variant: 'success',
                });
                window.location.reload();
            } else {
                enqueueSnackbar('Failed to update project! Try again.', {
                    key: 'handleUpdateProjectSuccess',
                    variant: 'error',
                });
            }
        }
        props.updateProjectsData();
        props.setLoading(false);
    };

    const handleProjectDetailClick = (data: ProjectType) => {
        if (data) {
            navigate(`/${data.id}/project-details`);
        }
    };

    const handleEditProject = (data: ProjectType) => {
        setProjectData(data);
        setIsEdit(true);
        setIsCreateModelOpen(true);
    };

    return (
        <div className="projects_list_cont">
            <div className="g_row g_tspacer3 g_bspacer2 width_fixer">
                <Typography variant="h5" component="div">
                    <Box fontWeight={600}>
                        {props.titleVariant === 'volunteer'
                            ? 'Current Projects'
                            : 'Projects'}
                    </Box>
                </Typography>
                {props.titleVariant === 'organization' && (
                    <Button
                        className="pl_createBtn"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setProjectData(undefined);
                            setIsCreateModelOpen(true);
                        }}
                    >
                        Create
                    </Button>
                )}
            </div>

            <div className="projects_list_in_cont">
                {props.projectsData.length === 0 && (
                    <Typography align="center">No Projects</Typography>
                )}
                {props.projectsData.map((item: ProjectType, index: number) => (
                    <ProjectCard2
                        documentId={item.documentId}
                        key={index}
                        projectId={item.id}
                        title={item.name}
                        description={item.description}
                        location={item.location}
                        image={item.image_url}
                        map={item.map_url}
                        onClick={handleProjectDetailClick}
                        handleEditProject={handleEditProject}
                        titleVariant={props.titleVariant}
                    />
                ))}
            </div>

            <Modal open={isCreateModalOpen} onClose={setIsCreateModelOpen}>
                <UpdateProject
                    isEdit={isEdit}
                    data={projectData}
                    onUpdate={handleUpdateProject}
                    onCreate={handleCreateProject}
                    onClose={setIsCreateModelOpen}
                />
            </Modal>

            {/* //TODO: To be removed after Stephanie approves this change
			<ModalPopup
                maxWidth={'lg'}
                isOpen={isDetailPopupOpen}
                handleClose={setIsDetailPopupOpen}
            >
                {projectDetails ? (
                    <ProjectDetails
                        projectDetails={projectDetails}
                        handleClose={setIsDetailPopupOpen}
                    />
                ) : (
                    <></>
                )}
            </ModalPopup> */}
        </div>
    );
};

export default ProjectsList;
