import React, { useCallback, useContext, useEffect, useState } from 'react';
import Image from 'material-ui-image';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    makeStyles,
    Paper,
    Tab,
    Typography,
    Snackbar,
    Backdrop,
} from '@material-ui/core';
import { AppRoutes } from 'App';
import firebase from 'utils/auth';
import { useSnackbar } from 'notistack';
import { OrganizationType } from 'types';
import BreadCrumb from 'Pages/BreadCrumbs/BreadCrumbs';
import FeaturedCard, { FeaturedCardProps } from 'components/FeaturedCard/FeaturedCard';
import { VolunteerProjectType } from 'types';
import { UserContext } from 'contexts/UserContext';
import { Alert } from '@material-ui/lab';
import {
    getProjectActivityType,
    getProjectType,
} from 'helpers/utils';
import dayjs from 'dayjs';
import useJoinProject from 'hooks/useJoinProject';
// import AboutOrg from './Tabs/AboutOrg/AboutOrg';
// import AboutUsSection from 'components/AboutUsSection/AboutUsSection';
import { useParams } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: 'white',
        height: '100%',
    },
    projectHeading: {
        padding: '10px',
    },
    name: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    description: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '500px',
    },
    address: {
        width: '200px',
        wordBreak: 'keep-all',
        fontWeight: 'bold',
    },
    paperImage: {
        width: '50%',
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    image: {
        maxWidth: '280px',
        maxHeight: '400px',
        '& img': {
            width: '100%',
        },
    },
    paperText: {
        width: '100%',
        textAlign: 'left',
        padding: theme.spacing(1),
        margin: '0 0 0 0',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[100],
    },
    projectTypeDetails: {
        fontWeight: 'bold',
    },
    divider: {
        margin: '10px 0 10px 0',
        width: '100%',
    },
    btnApply: {
        width: '50%',
        color: 'white',
        fontWeight: 'bold',
        backgroundColor: theme.palette.primary.main,
        '& > :hover': {
            color: theme.palette.primary.main,
        },
        '&.Mui-disabled': {
            opacity: 0.6,
            cursor: 'not-allowed',
            background: 'grey',
            color: 'white',
            pointerEvents: 'auto',
        },
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start ',
        width: '100%',
        margin: 'auto',
    },
    topArea: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: '3rem',
        backgroundColor: theme.palette.grey[100],
    },
    bottomArea: {
        width: '100%',
        margin: 'auto',
    },
    tabsContainer: {
        boxShadow: 'none',
    },
    tabPanel: {
        color: '#000',
        fontColor: '#000',
    },
    loaderStyle: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
    },
}));

function VolunteerProjectDetail() {
    const classes = useStyles();
    // const id = props.match?.params?.id;
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { isLoggedIn, userData } = useContext(UserContext);
    const [breadCrumbs, setBreadCrumbs] = useState<
        { label: string; path: string }[]
    >([]);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [projectDetails, setProjectDetails] = useState<VolunteerProjectType>();
   
    const makeBreadCrumbs = useCallback(() => {
        return [
            {
                label: 'Find a Project > ',
                path: `${AppRoutes.HowWorksVolunteer}`,
            },
            {
                label: 'Search > ',
                path: `${AppRoutes.volunteerOpportunities}`,
            },
            {
                label: `${projectDetails?.partnershipOpportunity} `,
                path: '',
            },
        ];
    }, [projectDetails]);

    const getProjectDetails = useCallback(async (id: string) => {
        const data = await firebase.getVolunteerProjectDetailsById(id);
        if (data) {
            setProjectDetails(data);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        id && getProjectDetails(id);
    }, [id, getProjectDetails]);

    useEffect(() => {
        const response = makeBreadCrumbs();
        setBreadCrumbs(response);
    }, [makeBreadCrumbs]);
   
    return isLoading ? (
        <Box className={classes.loaderStyle}>
            <Backdrop
                open={true}
                style={{
                    color: '#fff',
                    zIndex: 1000,
                }}
            >
                <CircularProgress color="secondary" />
            </Backdrop>
        </Box>
    ) : (
        <>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={4000}
                onClose={() => setShowSnackbar(false)}
            >
                <Alert
                    onClose={() => setShowSnackbar(false)}
                    severity="warning"
                >
                    Please Login to Apply
                </Alert>
            </Snackbar>
            <BreadCrumb breadCrumbs={breadCrumbs} />
            <Grid
                container
                justifyContent="space-between"
                className={classes.container}
            >
                <Grid item xs={12}>
                    <Box className={classes.projectHeading}>
                        <Typography variant="h3" className={classes.name}>
                            {`${projectDetails?.partnershipOpportunity} (${getProjectType(
                                projectDetails?.activity_type,
                            )})`}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} style={{ margin: '8' }}>
                    <Box className={classes.paperText}>
                        <Typography
                            variant="body1"
                            className={classes.projectTypeDetails}
                        >
                            {'Commitment Type (Online/In-Person):'}
                        </Typography>
                        <Typography variant="subtitle1">
                            {`${getProjectType(projectDetails?.activity_type)}`}
                        </Typography>
                        <Divider className={classes.divider} />
                        <Typography
                            variant="body1"
                            className={classes.projectTypeDetails}
                        >
                            {'Commitment Hours:'}
                        </Typography>
                        <Typography variant="subtitle1">
                            {`${getProjectActivityType(
                                projectDetails?.activity_duration_per_week,
                            )}`}
                        </Typography>
                        <Divider className={classes.divider} />
                        <Typography
                            variant="body1"
                            className={classes.projectTypeDetails}
                        >
                            {'Start Date:'}
                        </Typography>
                        <Typography variant="subtitle1">
                            {dayjs(projectDetails?.schedule_start).format(
                                'MM-DD-YYYY',
                            )}
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.projectTypeDetails}
                        >
                            {'End Date:'}
                        </Typography>
                        <Typography variant="body1">
                            {dayjs(projectDetails?.schedule_end).format(
                                'MM-DD-YYYY',
                            )}
                        </Typography>
                        <Divider className={classes.divider} />
                        <Typography
                            variant="body1"
                            className={classes.projectTypeDetails}
                        >
                            {'Cause(s):'}
                        </Typography>
                        <Typography variant="subtitle1">
                            <ul>
                                {projectDetails?.causes.length ? (
                                    projectDetails?.causes.map((cause) => {
                                        return <li key={cause}>{cause}</li>;
                                    })
                                ) : (
                                    <li>None</li>
                                )}
                            </ul>
                        </Typography>
                        <Divider className={classes.divider} />
                        <Typography
                            variant="body1"
                            className={classes.projectTypeDetails}
                        >
                            {'Skills/Categories:'}
                        </Typography>
                        <Typography variant="subtitle1">
                            <ul>
                                {projectDetails?.categories.length ? (
                                    projectDetails?.categories.map((skill) => {
                                        return <li key={skill}>{skill}</li>;
                                    })
                                ) : (
                                    <li>None</li>
                                )}
                            </ul>
                        </Typography>
                        <Divider className={classes.divider} />
                        <Typography
                            variant="body1"
                            className={classes.projectTypeDetails}
                        >
                            {'Volunteer Location:'}
                        </Typography>
                        <Typography variant="subtitle1">
                            {projectDetails?.location?.address?.label}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default VolunteerProjectDetail;
