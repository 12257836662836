import {
    Box,
    Button,
    CircularProgress,
    Container,
    TextField,
} from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import './HowItWorks.scss';
import { OrganizationType } from 'types';
import FeaturedCard from 'components/FeaturedCard/FeaturedCard';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useNavigate, useLocation } from 'react-router-dom';
import useAlgolia from 'hooks/useAlgolia';
import { isObjectEmpty } from 'helpers/utils';

export const OrganizationOpportunities = () => {
    const { organizationsIndex } = useAlgolia();
    const navigate = useNavigate();
    const location = useLocation();

    const [organizationSearch, setOrganizationSearch] = useState(
        (location?.state as any)?.search ?? '',
    );
    const [citySearch, setCitySearch] = useState(
        (location?.state as any)?.city ?? '',
    );
    const [searchedOrganizations, setSearchedOrganizations] = useState<
        OrganizationType[] | null
    >(null);

    const [isSearching, setIsSearching] = useState(true);
    const [cityOptions, setCityOptions] = useState<any>([]);

    const searchOrganization = useCallback(async () => {
        setIsSearching(true);
        const result = await organizationsIndex.search(organizationSearch, {
            optionalWords: [organizationSearch],
            filters:
                citySearch.trim() === '' ? undefined : `city:"${citySearch}"`,
        });
        setSearchedOrganizations(result.hits as any);
        setIsSearching(false);
    }, [citySearch, organizationSearch, organizationsIndex]);

    const getCities = useCallback(
        (search: string) => {
            organizationsIndex
                .searchForFacetValues('city', search)
                .then((result:any) => {
                    setCityOptions(result.facetHits);
                });
        },
        [organizationsIndex],
    );

    useEffect(() => {
        getCities(citySearch);
    }, [citySearch, getCities]);

    useEffect(() => {
        searchOrganization();
    }, [searchOrganization]);

    return (
        <div className="hwv_root">
            <div className="hwv_body g_wfull g_whitebg">
                <Container maxWidth="md">
                    <div className="hw_searcharea">
                        <div className="hw_searcharea_items">
                            <div className="hw_searcharea_input">
                                <TextField
                                    value={organizationSearch}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => setOrganizationSearch(e.target.value)}
                                    fullWidth
                                    label="Search by keyword"
                                    size="small"
                                    variant="outlined"
                                />
                            </div>
                            <div className="hw_searcharea_input">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={cityOptions}
                                    getOptionLabel={(option: any) =>
                                        option.value
                                    }
                                    renderOption={(option) => (
                                        <div
                                            className="hw_sc_suggestion"
                                            dangerouslySetInnerHTML={{
                                                __html: option.highlighted,
                                            }}
                                        />
                                    )}
                                    noOptionsText={'No city found'}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>,
                                            ) => setCitySearch(e.target.value)}
                                            label="Search by city"
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                            </div>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={searchOrganization}
                                className="g_primary_btn"
                            >
                                Search
                            </Button>
                        </div>
                    </div>

                    <div className="hw_organization_list">
                        {isSearching && (
                            <Box mt={12} mb={12}>
                                <CircularProgress />
                            </Box>
                        )}
                        {!isSearching &&
                            searchedOrganizations?.length === 0 && (
                                <h4>No Organization found!</h4>
                            )}
                        {!isSearching &&
                            searchedOrganizations?.map((item, index) => {
                                return (
                                    <FeaturedCard
                                        key={item.uid}
                                        id={item.objectID ?? ''}
                                        name={item.company}
                                        description={item.mission}
                                        location={
                                            isObjectEmpty(
                                                item.location?.address as any,
                                            )
                                                ? `${item.city}, ${item.country}`
                                                : `${item.location.address.label}`
                                        }
                                        image_url={
                                            item.cover_picture_url ??
                                            'https://firebasestorage.googleapis.com/v0/b/nvestpartners-prod.appspot.com/o/organizations%2FHd%20image.webp?alt=media&token=cca7256e-4710-4a00-af5c-276d5836d637'
                                        }
                                        variant="organization"
                                    />
                                );
                            })}
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default OrganizationOpportunities;
