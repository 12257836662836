import React, { FC } from 'react';
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import Login from './Pages/Login/Login';
import Signup from './Pages/Signup/Signup';
import Forgot from './Pages/Forgot/Forgot';
import About from './Pages/About/About';
import Profile from './Pages/Profile/Profile';
import ProfilePreview from './Pages/Profile/Profile';
import Header from './components/Header/header';
import Footer from './components/Footer/footer';
import SignupContextProvider from './contexts/SignupContext';
import CommonDataContextProvider from './contexts/CommonDataContext';
import './App.scss';
import { HowWorksVolunteer } from './Pages/HowItWorks/HowWorksVolunteer';
import { HowWorksOrganization } from './Pages/HowItWorks/HowWorksOrganization';
import VolunteerOpportunities from './Pages/HowItWorks/VolunteerOpportunities';
import OrganizationOpportunities from './Pages/HowItWorks/OrganizationOpportunities';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import OrganizationPublicPage from './Pages/OrganizationPublicPage/OrganizationPublicPage';
import Donation from './Pages/Donation/Donation';
import MessageContextProvider from './contexts/MessageContext';
import { HomePage } from './Pages/HowItWorks/HomePage';
import ScrollToTop from 'components/ScrollToTop';
import Newsletter from 'Pages/Newsletter';
import ProjectDetail from 'components/ProjectDetail/ProjectDetail';
import VolunteerProjectDetail from 'components/ProjectDetail/VolunteerProjectDetail';
import VolunteerProfilePage from './Pages/VolunteerProfilePage/VolunteerProfilePage';
import ChatCard from 'components/ChatCard/ChatCard';
import ContactUs from 'components/Contact';
import AboutNvest from './Pages/AboutNvest/AboutNvest';
import FounderStory from './Pages/FounderStory/FounderStory';
import OurTeam from './Pages/OurTeam/OurTeam';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsAndConditions from './Pages/TermsAndConditions';
import AccountApprovals from 'Pages/AccountApprovals';
import ProtectedRoute from 'components/ProtectedRoute';

export enum AppRoutes {
    Landing = '/',
    Login = '/login',
    Signup = '/signup',
    About = '/about',
    Forgot = '/forgot',
    Profile = '/profile',
    ProfilePreview = '/profile/:id',
    HowWorksVolunteer = '/how-it-works-volunteer',
    HowWorksOrganization = '/how-it-works-organization',
    opportunities = '/opportunities',
    volunteerOpportunities = '/opportunities/volunteer',
    organizationOpportunities = '/opportunities/organization',
    organizationHome = '/organization/:id',
    donation = '/donation',
    careers = '/careers',
    blog = '/blog',
    successStories = '/success-stories',
    contactUs = '/contact-us',
    faq = '/faq',
    newsletter = '/newsletter',
    projectDetails = '/:id/project-details',
    volunteerProjectDetails = '/:id/volunteer-project-details',
    chat = '/chat',
    VolunteerProfilePage = '/volunteer-profile-page/:id',
    AboutNvest = '/aboutnvest',
    FounderStory = '/founderstory',
    OurTeam = '/ourteam',
    PrivacyPolicy = '/privacy-policy',
    TermsAndCondition = '/terms-and-condition',
    AccountApprovals = '/account-approvals',
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#CB5F25',
        },
        secondary: {
            main: '#CB5F25',
        },
    },
});

const App: FC = () => {
    return (
        <>
            <ScrollToTop />
            <MuiThemeProvider theme={theme}>
                <CommonDataContextProvider>
                    <SignupContextProvider>
                        <MessageContextProvider>
                            <div className="app-wa app-wa-wrapper">
                                <div className="header_container">
                                    <Header showAvatar={true}></Header>
                                </div>
                                <div className="app-wa__main">
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={<HomePage />}
                                        />
                                        <Route
                                            path="/login"
                                            element={<Login />}
                                        />
                                        <Route
                                            path="/signup"
                                            element={<Signup />}
                                        />
                                        <Route
                                            path="/about"
                                            element={<About />}
                                        />
                                        <Route
                                            path="/forgot"
                                            element={<Forgot />}
                                        />
                                        <Route
                                            path="/profile"
                                            element={<Profile />}
                                        />
                                        <Route
                                            path="/profile/:id"
                                            element={<ProfilePreview />}
                                        />
                                        <Route
                                            path="/how-it-works-volunteer"
                                            element={<HowWorksVolunteer />}
                                        />
                                        <Route
                                            path="/volunteer-profile-page/:id"
                                            element={<VolunteerProfilePage />}
                                        />
                                        <Route
                                            path="/how-it-works-organization"
                                            element={<HowWorksOrganization />}
                                        />
                                        <Route
                                            path="/opportunities/volunteer"
                                            element={<VolunteerOpportunities />}
                                        />
                                        <Route
                                            path="/opportunities/organization"
                                            element={
                                                <OrganizationOpportunities />
                                            }
                                        />
                                        <Route
                                            path="/donation"
                                            element={<Donation />}
                                        />
                                        <Route
                                            path="/newsletter"
                                            element={<Newsletter />}
                                        />
                                        <Route
                                            path="/:id/project-details"
                                            element={<ProjectDetail />}
                                        />
                                        <Route
                                            path="/:id/volunteer-project-details"
                                            element={<VolunteerProjectDetail />}
                                        />
                                        <Route
                                            path="/organization/:id"
                                            element={<OrganizationPublicPage />}
                                        />
                                        <Route path="/careers" element={null} />
                                        <Route path="/blog" element={null} />
                                        <Route
                                            path="/success-stories"
                                            element={null}
                                        />
                                        {/* <Route path="/chat" element={<ChatCard />}/> */}
                                        {/*<Route path="/chat" element={(props: any) => (<ChatCard {...props} />)} /> */}
                                        <Route
                                            path="/contact-us"
                                            element={<ContactUs />}
                                        />
                                        <Route
                                            path="/aboutnvest"
                                            element={<AboutNvest />}
                                        />
                                        <Route
                                            path="/founderstory"
                                            element={<FounderStory />}
                                        />
                                        <Route
                                            path="/ourteam"
                                            element={<OurTeam />}
                                        />
                                        <Route
                                            path="/privacy-policy"
                                            element={<PrivacyPolicy />}
                                        />
                                        <Route
                                            path="/terms-and-condition"
                                            element={<TermsAndConditions />}
                                        />
                                        {/* Fallback route for 404 Not Found */}
                                        <Route
                                            path="*"
                                            element={
                                                <Navigate to="/login" replace />
                                            }
                                        />
                                        <Route
                                            path="/account-approvals"
                                            element={
                                                <ProtectedRoute
                                                    element={
                                                        <AccountApprovals />
                                                    }
                                                />
                                            }
                                        />
                                    </Routes>
                                </div>

                                <div className="app-wa__footer">
                                    <Footer></Footer>
                                </div>
                            </div>
                        </MessageContextProvider>
                    </SignupContextProvider>
                </CommonDataContextProvider>
            </MuiThemeProvider>
        </>
    );
};

export default App;
