import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { CommonDataContext } from 'contexts/CommonDataContext';
import {
    Box,
    Button,
    Tooltip,
    Card,
    CardContent,
    Typography,
    Link,
    Avatar,
    IconButton
} from '@material-ui/core';
import './ProjectCard.scss';
import { UserContext } from 'contexts/UserContext';
import { Edit, RestoreFromTrash } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import firebase from 'utils/auth';

interface VolunteerProjectCardProps {
    heading: string;
    subHeading: string;
    startTime: any;
    endTime: any;
    duration: number;
    volunteerLocation: number;
    projectId: string;
    handleIsEdit?: Function;
    handleDelete?: Function;
    volunteerSelectedProject:any;
    documentId?:string;
}

const useStyles = makeStyles({
    root: {
        // maxWidth: "45rem",
        width: '100%',
        marginBottom: 10,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    team: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    joinBtn: {
        width: '50%',
    },
});

const VolunteerProjectCard: FC<VolunteerProjectCardProps> = (props: VolunteerProjectCardProps) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [timingString, setTimingString] = useState('');
    const { volunteerLocations, volunteerTimings } =
        useContext(CommonDataContext);
    const { userData, isLoggedIn } = useContext(UserContext);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    
    const {
        documentId,
        volunteerLocation,
        duration,
        startTime,
        endTime,
        heading,
        subHeading,
        projectId,
        handleIsEdit,
        handleDelete,
        volunteerSelectedProject
    } = props;
    console.log("volunteerSelectedProject",volunteerSelectedProject)
    const location = useMemo(() => {
        if (volunteerLocations) {
            for (let i = 0; i < volunteerLocations.length; i++) {
                if (volunteerLocations[i].id === volunteerLocation) {
                    return volunteerLocations[i].name;
                }
            }
            return '';
        } else {
            return '';
        }
    }, [volunteerLocations, volunteerLocation]);

    const totalDuration = useMemo(() => {
        if (volunteerTimings) {
            for (let i = 0; i < volunteerTimings.length; i++) {
                if (volunteerTimings[i].id === duration) {
                    return volunteerTimings[i].name;
                }
            }
            return '';
        } else {
            return '';
        }
    }, [volunteerTimings, duration]);

    useEffect(() => {
        if (startTime && startTime) {
            setTimingString(
                dayjs(startTime).format('MM/DD/YYYY') +
                    ' - ' +
                    dayjs(endTime).format('MM/DD/YYYY'),
            );
        }
    }, [startTime, endTime]);
    const handleShowDetails = async () => {
        if (isLoggedIn && documentId) {
            const returnData = await firebase.getVolunteerProjectDetailsById(documentId);
            if (returnData) {
                navigate(`/${documentId}/volunteer-project-details`);
            }
        } else {
            setShowSnackbar(true);
        }
    };
    
    const handleEdit = async (e: any, projectId: string) => {
        e.preventDefault();
        e.stopPropagation();
        console.log("projectId",projectId)
        if (isLoggedIn && projectId && documentId) {
            const returnData = await firebase.getVolunteerProjectDetailsById(documentId);
            returnData && handleIsEdit && handleIsEdit(returnData);
            console.log("returenData is",returnData)
        } else {
            setShowSnackbar(true);
        }
    };

    const handleDeleteProject = async (projectId: string) => {
        if (isLoggedIn && documentId) {
            const returnData = await firebase.deleteVolunteerProject(documentId);
            if (returnData?.status === 200) {
                enqueueSnackbar('Volunteer Project Deleted!', {
                    key: 'handleVolunteerProjectDelete',
                    variant: 'success',
                });
                window.location.reload();
            } else {
                enqueueSnackbar('Failed to delete volunteer project! Try again.', {
                    key: 'handleVolunteerProjectDelete',
                    variant: 'error',
                });
            }
        } else {
            setShowSnackbar(true);
        }
    };
    return (
        <Card className={classes.root} variant="outlined">
            <CardContent className="pc_content">
                <div className="pc_section">
                    <Typography variant="h5" component="div">
                        <Link
                            component="button"
                            variant="h6"
                            onClick={() => {
                                handleShowDetails()
                            }}
                        >
                            <Box fontWeight={700}>{heading}</Box>
                        </Link>
                    </Typography>
                    {isLoggedIn &&
                        (userData?.account_type === 'Individual Partners') &&
                        (
                            <div className="action_container" style={{marginLeft:"-30%"}}>
                                <Tooltip title="Edit">
                                    <IconButton
                                        onClick={(e) => handleEdit(e,projectId)}
                                    >
                                        <Edit fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton
                                        onClick={() => handleDeleteProject(projectId)}
                                        // onClick={(e) => handleDelete && handleDelete(projectId,volunteerSelectedProject)}
                                    >
                                        <RestoreFromTrash color="error" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                    )}
                </div>
                <div className="pc_section pc_spaceTop">
                    <Typography variant="h6" component="div">
                        <Box fontWeight={600} mb={2}>
                            {subHeading}
                        </Box>
                    </Typography>
                    <Typography
                        variant="body1"
                        component="h1"
                        className={classes.pos}
                    >
                        {timingString}
                    </Typography>
                    
                    <Typography
                        variant="body1"
                        component="h1"
                        className={classes.pos}
                    >
                        {totalDuration}
                    </Typography>
                    <Typography
                        variant="body1"
                        component="h1"
                        className={classes.pos}
                    >
                        {location}
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};

export default VolunteerProjectCard;
