import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    createStyles,
    makeStyles,
    TextField,
    Theme,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useParams } from 'react-router-dom';
import ProjectsList from 'components/ProjectsList/ProjectsList';
import OrganizationBanner from 'components/OrganizationBanner/OrganizationBanner';

import firebase from 'utils/auth';
import { useSnackbar } from 'notistack';
import { OrganizationType, ProjectType } from 'types';

import Reviewes from 'components/Reviewes';
import AboutUsSection from 'components/AboutUsSection/AboutUsSection';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, className, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className={className}
            {...other}
        >
            {value === index && (
                <Box
                    p={3}
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '55rem',
            margin: 'auto',
        },
        topArea: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '3rem',
        },
        bottomArea: {
            width: '100%',
            maxWidth: '50rem',
        },
        tabsContainer: {
            boxShadow: 'none',
            width: '80%',
        },
        tabPanel: {
            color: '#000',
            fontColor: '#000',
            width: '100%',
            maxWidth: '50rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
);

interface OrganizationPublicPageProps {
    history: any;
    location: any;
    match: any;
}

const OrganizationPublicPage: React.FC = () => {
    const classes = useStyles();
    const { id } = useParams();
    const [value, setValue] = React.useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const [setIsLoading] = useState(false);
    const [projects, setProjects] = useState<ProjectType[]>([]);
    const [organizationData, setOrganizationData] =
        useState<OrganizationType | null>(null);
    const [organizationLocation, setOrganizationLocation] = useState<{
        label: string;
        value: Record<string, unknown>;
    }>({ label: '', value: {} });

    const updateProjectsData = useCallback(
        async (uid: string) => {
            let returnData = await firebase.getOrganizationProjectsDataByUid(
                uid,
            );
            if (returnData && returnData.status === 'ok') {
                setProjects(returnData.payload as []);
            } else {
                enqueueSnackbar('Error! Try refreshing the page.', {
                    key: 'updateProjectsDataError',
                    variant: 'error',
                });
                setProjects([]);
            }
        },
        [enqueueSnackbar],
    );

    const updateOrganizationData = useCallback(async () => {
        let returnData = await firebase.getOrganizationDataById(
            id,
        );
        if (returnData) {
            setOrganizationData(returnData);
        } else {
            enqueueSnackbar('Error! Try refreshing the page.', {
                key: 'updateAboutUsDataError',
                variant: 'error',
            });
            setOrganizationData(null);
        }
    }, [enqueueSnackbar, id]);

    useEffect(() => {
        updateOrganizationData();
    }, [id, updateOrganizationData]);

    useEffect(() => {
        if (organizationData && organizationData.uid) {
            updateProjectsData(organizationData.uid);
        }
    }, [organizationData, updateProjectsData]);

    useEffect(() => {
        if (organizationData && organizationData.location) {
            setOrganizationLocation({
                label: `${organizationData.location.address.label}`,
                value: organizationData.location.address.value,
            });
        }
    }, [organizationData]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className="g_whitebg">
            {!organizationData ? (
                <></>
            ) : (
                <>
                    <OrganizationBanner
                        variant="public"
                        companyName={organizationData.company}
                        location={organizationLocation}
                        socials={organizationData.socials}
                        coverPicture={organizationData?.cover_picture_url || ''}
                    />
                    <div className={classes.root}>
                        <div className={classes.topArea}>
                            <AppBar
                                className={classes.tabsContainer}
                                color="transparent"
                                position="static"
                            >
                                <Tabs
                                    indicatorColor="primary"
                                    textColor="primary"
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="simple tabs example"
                                    variant="scrollable"
                                    // centered
                                >
                                    <Tab label="About Us" {...a11yProps(0)} />
                                    <Tab label="Projects" {...a11yProps(1)} />
                                    <Tab label="Contact Us" {...a11yProps(2)} />
                                    <Tab label="Review" {...a11yProps(2)} />
                                </Tabs>
                            </AppBar>
                        </div>
                        <div className={classes.bottomArea}>
                            <TabPanel
                                className={classes.tabPanel}
                                value={value}
                                index={0}
                            >
                                <div>
                                    <AboutUsSection
                                        variant={'public'}
                                        overview={
                                            organizationData
                                                ? organizationData.overview
                                                : ''
                                        }
                                        vision={
                                            organizationData
                                                ? organizationData.vision
                                                : ''
                                        }
                                        mission={
                                            organizationData
                                                ? organizationData.mission
                                                : ''
                                        }
                                        successStories={
                                            organizationData
                                                ? organizationData.success_stories
                                                : ''
                                        }
                                        financials={
                                            organizationData
                                                ? organizationData.financials
                                                : ''
                                        }
                                        impact={
                                            organizationData
                                                ? organizationData.impact
                                                : ''
                                        }
                                        // ourTeam={
                                        //     organizationData
                                        //         ? organizationData.our_team
                                        //         : ''
                                        // }
                                        ourMembers={organizationData.ourMembers}
                                        organizationId={organizationData.id}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel
                                className={classes.tabPanel}
                                value={value}
                                index={1}
                            >
                                <div>
                                    {!projects ? (
                                        <CircularProgress />
                                    ) : (
                                        <>
                                            <ProjectsList
                                                titleVariant="volunteer"
                                                projectsData={projects}
                                                updateProjectsData={() => {
                                                    return;
                                                }}
                                                setLoading={setIsLoading}
                                            />
                                        </>
                                    )}
                                </div>
                            </TabPanel>
                            <TabPanel
                                className={classes.tabPanel}
                                value={value}
                                index={2}
                            >
                                <Box width="100%">
                                    <div className="g_row g_tspacer3 g_bspacer2 g_wfull">
                                        <Typography variant="h4">
                                            Contact Us
                                        </Typography>
                                    </div>
                                    <div className="g_row g_tspacer3 g_bspacer2">
                                        <TextField
                                            multiline
                                            minRows={9}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="g_row g_tspacer3 g_bspacer2 g_mauto">
                                        <Button variant="contained">
                                            Send
                                        </Button>
                                    </div>
                                </Box>
                            </TabPanel>
                            <TabPanel
                                className={classes.tabPanel}
                                value={value}
                                index={3}
                            >
                                {organizationData && (
                                    <Reviewes
                                        reviewes={organizationData.reviewes}
                                        organizationId={id??''}
                                    />
                                )}
                            </TabPanel>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default OrganizationPublicPage;
