import algoliasearch from 'algoliasearch/lite';
import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY } from 'const';

const searchClient = algoliasearch(
    ALGOLIA_APP_ID ?? '',
    ALGOLIA_SEARCH_KEY ?? '',
);
const projectsIndex = searchClient.initIndex(
    process.env.REACT_APP_ALGOLIA_PROJECT_INDEX ?? '',
);
const volunteerProjectsIndex = searchClient.initIndex(
    process.env.REACT_APP_ALGOLIA_PROJECT_INDEX ?? '',
);
const organizationsIndex = searchClient.initIndex(
    process.env.REACT_APP_ALGOLIA_ORGANIZATION_INDEX ?? '',
);
const usersIndex = searchClient.initIndex(
    process.env.REACT_APP_ALGOLIA_USER_INDEX ?? '',
);

export default function useAlgolia() {
    return {
        volunteerProjectsIndex,
        projectsIndex,
        organizationsIndex,
        usersIndex,
    };
}
