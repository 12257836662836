import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from 'contexts/UserContext';
import { UserType } from 'types';
import firebaseInstance from 'utils/auth';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    IconButton,
    Button,
    Fade,
    Backdrop,
    Modal,
    TextareaAutosize,
} from '@material-ui/core';
import { CheckCircle, Cancel } from '@material-ui/icons';
import './AccountApprovals.scss';
import { useSnackbar } from 'notistack';

function AccountApprovals() {
    const { enqueueSnackbar } = useSnackbar();
    const { isLoggedIn } = useContext(UserContext);
    const [userId, setUserId] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [accountApprovals, setAccountApprovals] = useState<UserType[]>([]);

    const getAccountsForApprovals = useCallback(async () => {
        const result = await firebaseInstance.getAccountsForApprovals();
        setAccountApprovals(result);
    }, []);

    useEffect(() => {
        getAccountsForApprovals();
    }, [getAccountsForApprovals]);

    const handleApprove = async (userId: string, email: string) => {
        firebaseInstance
            .approveUser(userId)
            .then((response) => {
                if (response.status === 200) {
                    const emailData = {
                        to: email, // Recipient's email address
                        subject: 'Account Approved - You Can Now Login', // Email subject
                        html: `
                            <p>Dear User,</p>
                            <p>Your account has been approved. You can now log in to your account.</p>
                            <p>Thank you!</p>
                        `,
                        text: `Dear User,\n\nYour account has been approved. You can now log in to your account.\n\nThank you!`,
                    };
                    enqueueSnackbar(response?.payload.message, {
                        variant: 'success',
                        autoHideDuration: 2500,
                    });
                    firebaseInstance.sendEmail({ ...emailData });
                    getAccountsForApprovals();
                    setShowModal(false);
                    setUserId('');
                }
            })
            .catch((error) => {
                enqueueSnackbar(error, {
                    variant: 'error',
                    autoHideDuration: 2500,
                });
            });
    };

    const handleReject = async (userId: string) => {
        setShowModal(true);
        setUserId(userId);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRejectRequest = async () => {
        if (rejectionReason === '') {
            enqueueSnackbar('Please provide a reason for rejection', {
                variant: 'error',
                autoHideDuration: 2500,
            });
        } else {
            firebaseInstance
                .rejectUser(userId, rejectionReason)
                .then((response) => {
                    if (response.status === 200) {
                        enqueueSnackbar(response?.payload.message, {
                            variant: 'success',
                            autoHideDuration: 2500,
                        });
                        getAccountsForApprovals();
                        setShowModal(false);
                        setUserId('');
                    }
                })
                .catch((error) => {
                    enqueueSnackbar(error, {
                        variant: 'error',
                        autoHideDuration: 2500,
                    });
                });
        }
    };

    if (!isLoggedIn) {
        return <Link to="/login" />;
    }

    return (
        <TableContainer className="approval-container" component={Box}>
            <Typography variant="h3" style={{ margin: '20px' }}>
                Account Approvals
            </Typography>

            {accountApprovals.length ? (
                <Table className="approval-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Company</TableCell>
                            <TableCell>Account Type</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Profile Complete</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {accountApprovals.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell>{`${user.first_name} ${user.last_name}`}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.company}</TableCell>
                                <TableCell>{user.account_type}</TableCell>
                                <TableCell>
                                    {user.created_at
                                        .toDate()
                                        .toLocaleDateString()}
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1">
                                        {'No'}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    {user.isRejected ? (
                                        <Typography variant="body1">
                                            {'Rejected'}
                                        </Typography>
                                    ) : (
                                        <>
                                            <IconButton
                                                color="primary"
                                                onClick={() =>
                                                    handleApprove(
                                                        user.id,
                                                        user.email,
                                                    )
                                                }
                                                aria-label="approve"
                                            >
                                                <CheckCircle />
                                            </IconButton>
                                            <IconButton
                                                color="default"
                                                onClick={() =>
                                                    handleReject(user.id)
                                                }
                                                aria-label="reject"
                                            >
                                                <Cancel />
                                            </IconButton>
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Typography variant="h6"> No accounts for approval </Typography>
            )}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="confirmation_modal"
                open={showModal}
                onClose={handleModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showModal}>
                    <div className="confirmation_modal_root">
                        <div className="lbl_rejection">
                            <h2 id="transition-modal-title">
                                Please provide a reason for rejection
                            </h2>
                        </div>
                        <div className="confirmation_modal_buttons">
                            <Box>
                                <TextareaAutosize
                                    maxRows={15}
                                    className="rejection_reason_text_area"
                                    onChange={(e) =>
                                        setRejectionReason(e.target.value)
                                    }
                                />
                            </Box>

                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                            >
                                <Button
                                    variant="contained"
                                    component="label"
                                    color="primary"
                                    onClick={handleRejectRequest}
                                >
                                    Reject
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleModalClose}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </TableContainer>
    );
}

export default AccountApprovals;
