import React, {
    SyntheticEvent,
    useCallback,
    useContext,
    useState,
} from 'react';
import { useNavigate } from 'react-router';
import {
    makeStyles,
    Container,
    Grid,
    Typography,
    Button,
    CssBaseline,
    TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import firebase from '../../utils/auth';
import { AppRoutes } from 'App';
import './Login.scss';
import { UserContext } from 'contexts/UserContext';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(12),
        marginBottom: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

type State = {
    email: string;
    password: string;
};

const initialState = {
    email: '',
    password: '',
};

const Login: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [state, setState] = useState<State>(initialState);
    const [error, setError] = useState('');
    const { isLoggedIn } = useContext(UserContext);

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const name = e.target.name;
            const value = e.target.value;
            //error: '',

            setState((s) => ({ ...s, [name]: value, error: '' }));
        },
        [],
    );

    const handleSubmit = useCallback(
        async (e: SyntheticEvent) => {
            e.preventDefault();
            const { email, password } = state;
            try {
                await firebase.login(email, password);
                /** This local storage is set to true so that when the page loads/refreshed
                 * the ProtectedRoute HOC does not redirect to the login page
                 */
                localStorage.setItem('isLoggedIn', 'true');
                navigate('/');
            } catch (error: any) {
                // error handling
                setError(error.message);
            }
        },
        [state, navigate],
    );

    return (
        <Container component="main" maxWidth="xs">
            {isLoggedIn && <Link to={'/'} />}
            <CssBaseline />
            <div className={classes.paper}>
                <div className="g_mt2">
                    <Typography component="h1" variant="h5">
                        Login to N-Vest Partners
                    </Typography>
                </div>
                <form
                    className={classes.form}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <TextField
                        required
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        type="text"
                        value={state.email}
                        autoComplete="email"
                        onChange={handleChange}
                        variant="filled"
                        style={{
                            backgroundColor: '#f3f9fd',
                            borderTopRightRadius: '.2rem',
                            borderTopLeftRadius: '.2rem',
                        }}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="Password"
                        name="password"
                        type="password"
                        value={state.password}
                        autoComplete="current-password"
                        onChange={handleChange}
                        variant="filled"
                        style={{
                            backgroundColor: '#f3f9fd',
                            borderTopRightRadius: '.2rem',
                            borderTopLeftRadius: '.2rem',
                        }}
                    />
                    {error && (
                        <Typography component="p" className="errorText">
                            {error}
                        </Typography>
                    )}

                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        className={classes.submit + ' g_primary_btn'}
                        color="secondary"
                    >
                        Log in
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link
                                style={{ color: 'black' }}
                                to={AppRoutes.Forgot}
                            >
                                Forgot password?
                            </Link>
                        </Grid>
                        {/* </Grid>
                        <Grid item>
                            <Link
                                style={{ color: 'black' }}
                                to={AppRoutes.Signup}
                            >
                                Signup for N-Vest Partners
                            </Link>
                        </Grid>*/}
                    </Grid>
                </form>
            </div>
        </Container>
    );
};
export default Login;
