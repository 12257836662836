import {
    Button,
    Checkbox,
    ListItemText,
    makeStyles,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import React, {
    ChangeEvent,
    useState,
    useRef,
    useEffect,
    useCallback,useContext
} from 'react';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { VolunteerProjectType } from 'types';
import firebase from 'utils/auth';
import { useSnackbar } from 'notistack';
import GoogleLocation from '../AutocompleteLocation/Location';
import { Autocomplete } from '@material-ui/lab';
import 'react-datepicker/dist/react-datepicker.css';
import 'date-fns';
import { UserContext } from 'contexts/UserContext';
import DatePickerCalendar from 'components/DatePicker/DatePickerCalender';

interface CreateAndUpdateVolunteerProjectProps {
    partnershipOpportunity: string;
    city: string;
    country: string;
    description: string;
    startDate: string;
    endDate: string;
    skills: Array<string>;
    onCreate: (volunteerProject: VolunteerProjectType) => void;
    onUpdate: (volunteerProject: VolunteerProjectType) => void;
    onClose: Function;
    data: VolunteerProjectType | undefined;
    isEdit: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '700px',
        maxHeight: '100vh',
        background: 'white',
        // borderRadius: '.6rem',
        padding: '1rem',
        boxSizing: 'border-box',
        overflowY: 'scroll',
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            margin: '1rem auto',
            maxHeight: '90vh',
        },
    },
    center: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    inputSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '49%',
        },
    },
    inputSectionFull: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
    },
    imageContainer: {
        maxWidth: '280px',
        maxHeight: '400px',
        '& img': {
            width: '100%',
        },
    },
    title: {
        fontSize: '1.1rem',
        fontWeight: 700,
        marginTop: '2rem',
        marginBottom: '.4rem',
    },
    helperText: {
        fontSize: '.8rem',
        color: 'gray',
        marginTop: '0',
        marginBottom: '.4rem',
    },
    uploadBtn: {
        fontSize: '1.1rem',
        fontWeight: 700,
        width: '10rem',
        height: '2rem',
        border: '.1rem solid gray',
        backgroundColor: 'lightgray',
    },
    buttons: {
        marginTop: '2rem',
        marginBottom: '1rem',
    },
    requirement: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& p': {
            margin: 0,
        },
    },
    selectRoot: {
        maxHeight: '2.5rem',
    },
    customInput: {
        width: '100%',
        cursor: 'pointer',
        '& .MuiInputBase-root': {
          backgroundColor: '#ffffff',
        },
      },
    dialogPaper: {
    borderRadius: '12px',
    overflow: 'hidden',
    border: 'none',
    },
    dialogHeader: {
    padding: '16px',
    backgroundColor: '#E97341', /* Orange background */
    textAlign: 'center',
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
    },
    dialogContent: {
    padding: '0px 16px 20px 16px',
    backgroundColor: 'white',
    },
    dialogActions: {
    justifyContent: 'space-between',
    backgroundColor: '#E97341', /* Orange background for footer */
    padding: '10px 16px',
    },
    actionButton: {
    color: 'white',
    fontWeight: 'bold',
    },
    cancelButton: {
    color: '#FFC4A3', /* Light orange for Cancel */
    },
}));

const CreateAndUpdateVolunteerProject = (props: CreateAndUpdateVolunteerProjectProps) => {
    const classes = useStyles();
    const { data, isEdit } = props;
    console.log("data is",data)
    const [partnershipOpportunity, setPartnershipOpportunity] = useState<string>(data?.partnershipOpportunity || '');
    const [type, setType] = useState<number>(data?.activity_type || 1);
    const [workPerWeek, setWorkPerWeek] = useState<number>(data?.activity_duration_per_week || 1,);
    const [startDate, setStartDate] = useState(data?.schedule_start || new Date().toISOString());
    const [endDate, setEndDate] = useState(data?.schedule_end || new Date().toISOString());
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(new Date())
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(new Date())
    const [causes, setCauses] = useState<string[]>(data?.causes || []);
    const [categories, setCategories] = useState<string[]>(data?.categories || [],);
    const [causesList, setCausesList] = useState<string[] | null>(null);
    const [categoriesList, setCategoriesList] = useState<string[] | null>(null);
    const [address, setAddress] = useState<any>(data?.location?.address);
    const [open, setOpen] = useState(false);
    const [selectedField, setSelectedField] = useState<string | null>(null);
    const [description, setDescription] = useState(data?.description || '');
    const { enqueueSnackbar } = useSnackbar();
    const { userData, isLoggedIn } = useContext(UserContext);
    console.log("user data",userData)
    console.log("firebase.auth.currentUser?.uid",firebase.auth.currentUser?.uid)
    useEffect(() => {
        if (data?.schedule_start) {
            setSelectedStartDate(new Date(data.schedule_start)); // Use setter to update state
        }
        if (data?.schedule_end) {
            setSelectedEndDate(new Date(data.schedule_end)); // Use setter to update state
        }
    }, [data]);
    const fetchListData = useCallback(async () => {
        const causes = await firebase.getAllCauses();
        setCausesList(causes as []);
        const categories = await firebase.getAllCategories();
        setCategoriesList(categories as []);
    }, []);

    useEffect(() => {
        fetchListData();
    }, [fetchListData]);
    const handleSelectChange = (
        event: React.ChangeEvent<{}>,
        value: string[],
    ) => {
        setCauses(value);
    };
    const handleSkillSelectChange = (
        event: React.ChangeEvent<{}>,
        value: string[],
    ) => {
        setCategories(value);
    };
   
    const handleStartDateChange = (date: Date | null) => {
        if (date) {
        const isoDate = date.toISOString(); // Convert to ISO format
        console.log("ISO Date:", isoDate);
        console.log("date:", date);
        setStartDate(isoDate); // Set ISO string
        setSelectedStartDate(date); // Set ISO string
        } else {
            setStartDate(null); // Handle null case
            setSelectedStartDate(null); // Handle null case
        }
        handleClose();
    }; 
    const handleEndDateChange = (date: Date | null) => {
        if (date) {
        const isoDate = date.toISOString(); // Convert to ISO format
        console.log("ISO Date:", isoDate);
        console.log("date:", date);
        setEndDate(isoDate); // Set ISO string
        setSelectedEndDate(date); // Set ISO string
        } else {
            setEndDate(null); // Handle null case
            setSelectedEndDate(null); // Handle null case
        }
        handleClose();
    };
    const getGeoCodeByAddress = async (location: any) => {
        try {
            const results = await geocodeByAddress(location);
            const { lat, lng } = await getLatLng(results[0]);
            const coordinates = { lat, lng };
            return coordinates;
        } catch (err) {}
        return location;
    };
    const handleOpen = (field: string) => {
        setSelectedField(field);
        setOpen(true);
    };
    const handleClose = () => {
    setOpen(false); 
    };
    const handleSubmit = async () => {
        if (partnershipOpportunity.trim() === '') {
            enqueueSnackbar('Please mention a partnership opportunity.', {
                variant: 'error',
            });
        }else if (description?.trim() === '') {
            enqueueSnackbar('Please describe your project.', {
                variant: 'error',
            });
        } else if (startDate.trim() === '') {
            enqueueSnackbar('Please mention a start date.', {
                variant: 'error',
            });
        } else if (endDate.trim() === '') {
            enqueueSnackbar('Please mention a end date.', {
                variant: 'error',
            });
        }else if (causes.length === 0) {
            enqueueSnackbar('Please mention atleast one cause.', {
                variant: 'error',
            });
        } else if (categories.length === 0) {
            enqueueSnackbar('Please mention atleast one category.', {
                variant: 'error',
            });
        } else {
            const locationCoordinates = address?.label
                ?.split(',')
                ?.slice(0, 1)
                .toString();
            const location = await getGeoCodeByAddress(locationCoordinates);

            isEdit
                ? props.onUpdate(
                      {
                          id: data?.id || '',
                          partnershipOpportunity: partnershipOpportunity,
                          description: description!,
                          schedule_start: startDate,
                          schedule_end: endDate,
                          volunteer_id: firebase.auth.currentUser?.uid ?? '',
                          team_members: [],
                          team_member_ids: [],
                          map_url: '',
                          skills: [],
                          activity_type: type,
                          activity_duration_per_week: workPerWeek,
                          causes: causes,
                          categories: categories,
                          documentId:data?.documentId,
                          location: {
                              address,
                              latitude: location.lat,
                              longitude: location.lng,
                          }
                      }
                  )
                : props.onCreate(
                      {
                          id: '',
                          partnershipOpportunity: partnershipOpportunity,
                          description: description!,
                          schedule_start: startDate,
                          schedule_end: endDate,
                          volunteer_id: firebase.auth.currentUser?.uid ?? '',
                          team_members: [],
                          team_member_ids: [],
                          map_url: '',
                          skills: [],
                          activity_type: type,
                          activity_duration_per_week: workPerWeek,
                          causes: causes,
                          categories: categories,
                          location: {
                              address,
                              latitude: location.lat,
                              longitude: location.lng,
                          }
                      }
                  );
        }
    };
    return (
        <div className={classes.root}>
            {/* partnershipOpportunity */}
            <div className={classes.row}>
                <div className={classes.inputSectionFull}>
                    <p className={classes.title}>Project Opportunity</p>
                    <TextField
                        value={partnershipOpportunity}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setPartnershipOpportunity(e.target.value)
                        }
                    />
                </div>
            </div> 
            {/* Commitment Type = type */}
            <div className={classes.row}>
                <div className={classes.inputSection}>
                    <p className={classes.title}>Commitment Type</p>
                    <Select
                        value={type}
                        onChange={(event: React.ChangeEvent<any>) => {
                            setType(event.target.value);
                        }}
                        fullWidth
                        variant="outlined"
                        className={classes.selectRoot}
                    >
                        <MenuItem value={1}>Online</MenuItem>
                        <MenuItem value={2}>In-Person</MenuItem>
                        <MenuItem value={3}>Both</MenuItem>
                    </Select>
                </div>
                <div className={classes.inputSection}>
                <p className={classes.title}>Commitment Hours</p>
                <Select
                    value={workPerWeek}
                    onChange={(event: React.ChangeEvent<any>) => {
                        setWorkPerWeek(event.target.value);
                    }}
                    fullWidth
                    variant="outlined"
                    className={classes.selectRoot}
                >
                    <MenuItem value={1}>1-2 hours/week</MenuItem>
                    <MenuItem value={2}>3-4 hours/week</MenuItem>
                    <MenuItem value={3}>5-6 hours/week</MenuItem>
                    <MenuItem value={4}>7 Hours+/week</MenuItem>
                </Select>
                </div>
            </div>
            {/* Start/End Date */}
            <div >
                {/* Start Date */}
                <div>
                    <p
                    style={{
                        fontSize: '1.1rem',
                        fontWeight: 700,
                        marginTop: '2rem',
                        marginBottom: '.4rem',
                    }}
                    >Start Date</p>
                    <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onClick={() => handleOpen("start")} // Open the dialog for Start Date
                    value={selectedStartDate ? selectedStartDate.toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" }) : ""}
                    placeholder="Select a start date"
                    InputProps={{ readOnly: true }}
                    style={{ cursor: "pointer", backgroundColor: "#fff" }}
                    />
                </div>
                {/* End Date */}
                <div>
                    <p style={{
                        fontSize: '1.1rem',
                        fontWeight: 700,
                        marginTop: '2rem',
                        marginBottom: '.4rem',
                    }}>End Date</p>
                    <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onClick={() => handleOpen("end")} // Open the dialog for End Date
                    value={selectedEndDate ? selectedEndDate.toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" }) : ""}
                    placeholder="Select an end date"
                    InputProps={{ readOnly: true }}
                    style={{ cursor: "pointer", backgroundColor: "#fff" }}
                    />
                </div>
                <DatePickerCalendar
                handleClose={handleClose}
                selected={
                selectedField === "start"
                    ? selectedStartDate
                    : selectedEndDate
                }
                handleChange={
                selectedField === "start"
                    ? handleStartDateChange
                    : handleEndDateChange
                } 
                minDate={
                    selectedField === "start"
                      ? new Date("2010-01-01")
                      : selectedStartDate || undefined
                }
                open={open}
                />
                {selectedStartDate!==null && selectedField === "end" && selectedEndDate && selectedEndDate < selectedStartDate && (
                    <p className="error-message">End date should not be before Start date!</p>
                )}
            </div>
            {/* causes    */}
            <div className={classes.row}>
                <div className={classes.inputSectionFull}>
                    <p className={classes.title}>Cause</p>
                    <p className={classes.helperText}>
                        This will help organizations find this opportunity
                    </p>

                    <Autocomplete
                        multiple
                        value={causes}
                        onChange={handleSelectChange}
                        disableCloseOnSelect
                        options={causesList ?? []}
                        getOptionLabel={(option) => option}
                        fullWidth
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox checked={selected} />
                                <ListItemText primary={option} />
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Causes"
                                placeholder="Select Causes"
                                size="small"
                            />
                        )}
                    />
                </div>
            </div>
            {/* categories - Skills/Categories*/}
            <div className={classes.row}>
                <div className={classes.inputSectionFull}>
                    <p className={classes.title}>Skills/Categories</p>
                    <p className={classes.helperText}>
                        Please select the skills you can offer organizations
                    </p>
                    <Autocomplete
                        multiple
                        value={categories}
                        onChange={handleSkillSelectChange}
                        disableCloseOnSelect
                        options={categoriesList ?? []}
                        fullWidth
                        getOptionLabel={(option) => option}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox checked={selected} />
                                <ListItemText primary={option} />
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Skills/Categories"
                                placeholder="Select Skills/Categories"
                                size="small"
                            />
                        )}
                    />
                </div>
            </div>
            {/* address - Location */}
            <div className={classes.row}>
                <div className={classes.inputSectionFull}>
                    <p className={classes.title}>Location</p>

                    <GoogleLocation
                        selectProps={{
                            isClearable: true,
                            value: address,
                            onChange: (val: any) => {
                                setAddress(val);
                            },
                            placeholder: 'Location',
                        }}
                    />
                </div>
            </div>
            {/* description - Project Overview */}
            <div className={classes.row}>
                <div className={classes.inputSectionFull}>
                    <p className={classes.title}>Project Overview</p>
                    <TextField
                        value={description}
                        variant="outlined"
                        size="small"
                        fullWidth
                        multiline
                        minRows={5}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setDescription(e.target.value)
                        }
                    />
                </div>
            </div>
            <div className={`${classes.row} ${classes.buttons}`}>
                <div className={classes.inputSection}>
                    <Button
                        fullWidth
                        variant="outlined"
                        color={'primary'}
                        onClick={() => props.onClose(false)}
                    >
                        Cancel
                    </Button>
                </div>
                <div className={classes.inputSection}>
                    <Button
                        fullWidth
                        variant="contained"
                        color={'primary'}
                        onClick={handleSubmit}
                    >
                        {isEdit ? 'Update' : 'Create'}
                    </Button>
                </div>
            </div>
        </div>   
    );
}
CreateAndUpdateVolunteerProject.defaultProps = {
    city: '',
    country: '',
    description: '',
    startDate: '2021-01-01',
    endDate: '2021-01-01',
    skills: [],
    imageUrl: '',
    partnershipOpportunity: '',
};

export default CreateAndUpdateVolunteerProject;