import {
    Box,
    CircularProgress,
    IconButton,
    makeStyles,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import React, { useCallback, useContext, useState, ChangeEvent } from 'react';
import { SocialMediaType } from 'types';
import EditSocialsPopup from './EditSocialsPopup';
import { Edit, CancelOutlined, Check } from '@material-ui/icons';
import SocialIcon from './SocialIcon';
import ProfilePictureUpdator from '../MyInformation/ProfilePictureUpdator';
import CoverPictureUploader from '../MyInformation/CoverPictureUploader';
import { UserContext } from 'contexts/UserContext';
import firebase from 'utils/auth';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import GoogleLocation from '../../components/AutocompleteLocation/Location';
import { Location } from '../../types/index';

interface OrganizationBannerProps {
    variant: 'private' | 'public';
    companyName: string;
    location: {
        label: string;
        value: Record<string, unknown>;
    };
    mobileViewBig?: boolean;
    socials: Array<SocialMediaType>;
    updateSocials?: (a: Array<SocialMediaType>) => void;
    coverPicture: string;
    updateOrganization?: (orgData: {
        company: string;
        location: Location;
    }) => void;
    onChangeOrganizationName?: (orgName: string) => void;
    onChangeOrganizationLocation?: (location: {
        label: string;
        value: Record<string, unknown>;
    }) => void;
}

interface ParamTypes {
    id: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#595827',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        // maxWidth: '55rem',
        padding: '0',
        // margin: 'auto',
        color: 'white',
    },
    location: {
        margin: '1rem',
    },
    image: {
        width: '100%',
        height: '200px',
        objectFit: 'cover',
    },
    companyName: {
        color: 'white !important',
    },
}));

const OrganizationBanner = (props: OrganizationBannerProps) => {
    const classes = useStyles();
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { userData, isLoggedIn } = useContext(UserContext);
    const { onChangeOrganizationLocation, location, companyName } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [isEditOrganization, setIsEditOrganization] = useState(false);
    const [isEditSocialMediaOpen, setIsEditSocialMediaOpen] = useState(false);

    const handleUpdateAvatar = async (file: File) => {
        setIsLoading(true);
        let error = await firebase.updateAvatar(file);
        setIsLoading(false);
        if (error) {
            enqueueSnackbar('Network Issue!', {
                key: error as any,
                variant: 'error',
            });
        }
    };

    const handleUpdateCoverPicture = async (file: File) => {
        setIsLoading(true);
        if (userData) {
            let error = await firebase.updateCoverPicture(
                file,
                userData.organization_id,
                userData.id,
            );
            setIsLoading(false);
            if (error) {
                enqueueSnackbar('Network Issue!', {
                    key: error,
                    variant: 'error',
                });
            }
        }
    };

    return (
        <div className={classes.root}>
            {isLoading && <CircularProgress />}
            {props.variant === 'private'
                ? isLoggedIn &&
                  (!id || id === userData?.organization_id) && (
                      <CoverPictureUploader
                          img={userData?.cover_picture_url || ''}
                          handleUpdateAvatar={handleUpdateCoverPicture}
                      />
                  )
                : props.coverPicture && (
                      <img
                          className={classes.image}
                          src={props.coverPicture}
                          alt=""
                      />
                  )}
            <EditSocialsPopup
                isOpen={isEditSocialMediaOpen}
                initialValue={props.socials ?? []}
                onUpdate={props.updateSocials}
                onClose={useCallback(() => setIsEditSocialMediaOpen(false), [])}
            />

            <Box display={'flex'}>
                {isEditOrganization ? (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        className="edit-organization-container"
                    >
                        <Box padding="8px">
                            <TextField
                                value={companyName}
                                variant="outlined"
                                size="small"
                                label="Organization Name"
                                fullWidth
                                InputProps={{
                                    className: classes.companyName,
                                }}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    props.onChangeOrganizationName!(
                                        e.target.value,
                                    )
                                }
                            />
                        </Box>
                        <Box padding="8px">
                            <GoogleLocation
                                selectProps={{
                                    isClearable: true,
                                    value: location,
                                    onChange: (val: {
                                        label: string;
                                        value: Record<string, unknown>;
                                    }) => {
                                        onChangeOrganizationLocation!(val);
                                    },
                                    placeholder: 'Location',
                                }}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Box>
                        <Box display={'flex'} justifyContent={'center'}>
                            <Typography variant="h4">{companyName}</Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'center'}>
                            <Typography className={classes.location}>
                                {location?.label}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {props.variant === 'private' &&
                    (isEditOrganization ? (
                        <Box>
                            <Tooltip title="Cancel">
                                <IconButton
                                    onClick={() => {
                                        setIsEditOrganization(false);
                                    }}
                                >
                                    <CancelOutlined
                                        fontSize="medium"
                                        className="g_whitetext"
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Save">
                                <IconButton
                                    onClick={() => {
                                        if (companyName && !!location) {
                                            props.updateOrganization!({
                                                company: companyName,
                                                location: {
                                                    address: location,
                                                    latitude: 0,
                                                    longitude: 0,
                                                },
                                            });
                                        } else {
                                            enqueueSnackbar(
                                                'Organization data cannot be empty!',
                                                {
                                                    key: 'Empty organization data',
                                                    variant: 'error',
                                                },
                                            );
                                        }
                                        setIsEditOrganization(false);
                                    }}
                                >
                                    <Check
                                        fontSize="medium"
                                        className="g_whitetext"
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    ) : (
                        <Box>
                            <Tooltip title="Edit">
                                <IconButton
                                    onClick={() => setIsEditOrganization(true)}
                                >
                                    <Edit
                                        fontSize="medium"
                                        className="g_whitetext"
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    ))}
            </Box>

            <div>
                {props.socials?.map((item: SocialMediaType, index: number) => (
                    <SocialIcon key={index} url={item.url} name={item.type} />
                ))}
                {props.variant === 'private' && (
                    <>
                        <Tooltip title="edit">
                            <IconButton
                                onClick={() => setIsEditSocialMediaOpen(true)}
                            >
                                <Edit
                                    fontSize="medium"
                                    className="g_whitetext"
                                />
                            </IconButton>
                        </Tooltip>
                        {isLoading ? (
                            <CircularProgress />
                        ) : (
                            props.mobileViewBig && (
                                <div className="mi_avatarContainer">
                                    <ProfilePictureUpdator
                                        img={userData?.avatar || ''}
                                        handleUpdateAvatar={handleUpdateAvatar}
                                    />
                                </div>
                            )
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default OrganizationBanner;
