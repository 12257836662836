import React, { createContext } from 'react';
import useVolunteerPersonalProjects from 'hooks/useVolunteerPersonalProjects';
import { VolunteerProjectsContext } from './VolunteerProjectsContext';
const VolunteerProjectsContextProvider = (props) => {
    const { volunteerProjects, isLoading } = useVolunteerPersonalProjects();
    console.log("volunteerProjects",volunteerProjects)
    return (
        <VolunteerProjectsContext.Provider value={{ volunteerProjects, isLoading }}>
            {props.children}
        </VolunteerProjectsContext.Provider>
    );
};

export default VolunteerProjectsContextProvider;
