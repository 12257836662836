import React, { FunctionComponent } from 'react';
import { v1 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';

type BreadCrumbsProps = {
    breadCrumbs?: { label: string; path: string }[];
};

const BreadCrumb: FunctionComponent<BreadCrumbsProps> = (props) => {
    const { breadCrumbs } = props;
    const navigate = useNavigate();

    const routeChange = (path: string) => {
        if (window.location.pathname !== path) {
            navigate(path, { replace: true }); 
        }
    };
    return (
        <div className="bread-crumbs">
            {breadCrumbs?.map((item) => {
                return (
                    <div key={uuid()} onClick={() => routeChange(item.path)}>
                        {item?.label}&nbsp;
                    </div>
                );
            })}
        </div>
    );
};

export default BreadCrumb;
