import { Box, CircularProgress, Typography } from '@material-ui/core';
import useAlgolia from 'hooks/useAlgolia';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { ProjectType, UserType, VolunteerProjectType } from '../../types';
import VolunteerProjectCard from 'components/ProjectCard/VolunteerProjectCard';

export interface ProjectsListProps {
    projectsData: Array<ProjectType>;
    volunteerProjectsData: Array<VolunteerProjectType>;
}

const VolunteerProjectsSuggestion: FC<ProjectsListProps> = ({
    projectsData,volunteerProjectsData
}: ProjectsListProps) => {
    const { usersIndex, volunteerProjectsIndex } = useAlgolia();
    const [isLoading, setIsLoading] = useState(true);
    const [suggestions, setSuggestions] = useState<VolunteerProjectType[]>([]);
    
    const fetchSuggestions = useCallback(async () => {
        if (projectsData && volunteerProjectsData) {
            console.log("projectsData is:", projectsData);
            console.log("volunteerProjectsData is:", volunteerProjectsData);
            setIsLoading(true);
            const projectSkills: string[] = [];
            for (let i = 0; i < projectsData.length; i += 1) {
                for (let j = 0; j < projectsData[i].categories.length; j += 1) {
                    if (!projectSkills.includes(projectsData[i].categories[j])) {
                        projectSkills.push(projectsData[i].categories[j]);
                    }
                }
            }
            const results = getMatchingProjects(projectSkills,volunteerProjectsData)
            console.log("results",results)
            setSuggestions(results)
            setIsLoading(false);
            // console.log("projectSkills:", projectSkills);
            // console.log("useIndex:", usersIndex);
    
            // try {
            //     // Properly format the filters query
            //     const formattedFilters = projectSkills
            //         .map((skill) => `skills:"${skill.replace(/"/g, '\\"')}"`) // Escape quotes in skill names
            //         .join(' OR ');
    
            //     const result = await volunteerProjectsIndex.search('', {
            //         filters: formattedFilters,
            //         hitsPerPage: 6,
            //     });
    
            //     console.log("result:", result);
    
            //     // Update state with suggestions
            //     setSuggestions(result.hits as any);
            // } catch (error) {
            //     console.error("Error during Algolia search:", error);
            // } finally {
            //     setIsLoading(false);
            // }
        }
    }, [projectsData,volunteerProjectsData, volunteerProjectsIndex]);
    
    const getMatchingProjects=(projectSkills:string[],volunteerProjectsData:VolunteerProjectType[]) =>{
        const matchingProjects: VolunteerProjectType[] = [];
        for (let i = 0; i < volunteerProjectsData.length; i++) {
            if (matchingProjects.length >= 3) break; 
            const project: VolunteerProjectType = volunteerProjectsData[i];
            const matchingCategories = project.categories.filter((category: any) => projectSkills.includes(category));
            if (matchingCategories.length >= 1) {
            matchingProjects.push(project)
            }
        }
        return matchingProjects
    }
    
    useEffect(() => {
        if (projectsData) {
            fetchSuggestions();
        }
    }, [projectsData, fetchSuggestions]);

    if (isLoading) {
        return (
            <Box
                width="100%"
                height="16rem"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div>
            <div className="g_row g_tspacer3 g_bspacer2 width_fixer">
                <Typography variant="h5" component="div">
                    <Box fontWeight={600}>Volunteer Projects</Box>
                </Typography>
            </div>
            <Box
                maxWidth="600px"
                m="auto"
                display="flex"
                flexWrap="wrap"
                gridGap="1rem"
            >
                {suggestions.length === 0 && (
                    <Typography>No volunteer projects found!</Typography>
                )}
                {suggestions.map(
                      (project: VolunteerProjectType, index: number) => (
                          <VolunteerProjectCard
                              key={index}
                              documentId={project.documentId}
                              projectId={project.id}
                              heading={project.partnershipOpportunity}
                              subHeading={project.causes.join(', ')}
                              startTime={project.schedule_start}
                              endTime={project.schedule_end}
                              duration={project.activity_duration_per_week}
                              volunteerLocation={project.activity_type}
                              volunteerSelectedProject={project}
                          />
                      ),
                  )}
            </Box>
        </div>
    );
};

export default VolunteerProjectsSuggestion;
