import {
    Box,
    Button,
    Container,
    Snackbar,
    TextField,
    Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import './HowItWorks.scss';
import OrganizationList from '../../components/FeaturedList/FeaturedOrganizations';
import firebase from '../../utils/auth';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'App';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import { UserContext } from 'contexts/UserContext';
import { Link } from 'react-router-dom';
import useAlgolia from 'hooks/useAlgolia';

export const HowWorksOrganization = () => {
    const navigate = useNavigate();
    const { organizationsIndex } = useAlgolia();
    const { isChecking, isLoggedIn } = useContext(UserContext);
    const [cityOptions, setCityOptions] = useState<any>([]);
    const [citySearch, setCitySearch] = useState('');
    const [organizationSearch, setOrganizationSearch] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
    const [value, setValue] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [suggestions, setSuggestions] = useState<any>([]);

    const getCities = useCallback((search: string) => {
        organizationsIndex
            .searchForFacetValues('city', search)
            .then((result: any) => {
                setCityOptions(
                    result.facetHits.map((result: any) => {
                        return {
                            label: result.value,
                            value: result.value,
                            highlighted: result.highlighted,
                        };
                    }),
                );
            });
    }, []);

    useEffect(() => {
        if (organizationSearch) {
            organizationsIndex.search(organizationSearch).then((data: any) => {
                setSuggestions(
                    data.hits.map((result: any) => {
                        return {
                            label: result.company,
                            value: result.company,
                            highlighted: result._highlightResult.company.value,
                        };
                    }),
                );
            });
        } else {
            setValue('');
            setSuggestions([]);
        }
    }, [organizationSearch]);

    const goToSearchPage = () => {
        // if (isLoggedIn) {
        //     history.push(AppRoutes.organizationOpportunities, {
        //         search: organizationSearch,
        //         city: citySearch,
        //     });
        // } else {
        //     setShowSnackbar(true);
        // }
        navigate(AppRoutes.organizationOpportunities, {
            state: {
                search: organizationSearch,
                city: citySearch,
            },
        });
    };

    const handleSeeFullList = () => {
        if (isLoggedIn) {
            navigate(AppRoutes.organizationOpportunities);
        } else {
            setShowSnackbar(true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (citySearch) getCities(citySearch);
    }, [getCities, citySearch]);

    // https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
    // function escapeRegexCharacters(str: string) {
    //     return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    // }

    const handleChange = (e: React.ChangeEvent<object>, data: any) => {
        setValue(data);
        setOrganizationSearch(data?.value);
    };

    const handleCityAutoCompleteChange = (
        e: React.ChangeEvent<object>,
        data: any,
    ) => {
        setCitySearch(data?.value);
        setSelectedCity(data);
        !data && setCityOptions([]);
    };

    return (
        <div className="hwv_root">
            <Snackbar
                open={showSnackbar}
                autoHideDuration={4000}
                onClose={() => setShowSnackbar(false)}
            >
                <Alert
                    onClose={() => setShowSnackbar(false)}
                    severity="warning"
                >
                    Please Login to see full list of organizations
                </Alert>
            </Snackbar>
            {/* <div className="g_profile_banner">
                <img src={logo} alt="logo" />
            </div> */}
            <div className="hwv_body g_wfull g_whitebg">
                <Container maxWidth="md">
                    <div className="g_row g_tspacer3 g_bspacer2">
                        <Typography variant="h5">
                            How it Works for Organizations
                        </Typography>
                    </div>
                    <div className="hw_process">
                        <div className="hw_process_section">
                            <div className="hw_process_section_title">
                                <Typography variant="h5">Sign Up</Typography>
                            </div>
                            <Typography>
                                Answer our call to{' '}
                                <Link className="" to={AppRoutes.Signup}>
                                    “Join Now”
                                </Link>{' '}
                                and tell us about your organization! We want to
                                learn about the impact your organization has
                                made through its work and what goals you have
                                for the future.
                            </Typography>
                        </div>
                        <div className="hw_process_section">
                            <div className="hw_process_section_title">
                                <Typography variant="h5">Source</Typography>
                            </div>
                            <Typography>
                                Once we have reviewed and approved your
                                application, you will then create a custom
                                organization profile page. You will use this
                                page to source for partners based on specific
                                project needs.
                            </Typography>
                        </div>
                        <div className="hw_process_section">
                            <div className="hw_process_section_title">
                                <Typography variant="h5">
                                    Work Together
                                </Typography>
                            </div>
                            <Typography>
                                Find your perfect match. You will be connected
                                to partners who match the specific project needs
                                you have identified.
                            </Typography>
                        </div>
                    </div>
                    <div className="hw_searcharea">
                        <div className="g_mb1">
                            <Typography variant="h6">
                                Search for an Organization
                            </Typography>
                        </div>
                        <div className="hw_searcharea_items">
                            <div className="hw_searcharea_input">
                                <Autocomplete
                                    id="keyword-box-demo"
                                    value={value}
                                    options={suggestions}
                                    getOptionLabel={(option: any) => {
                                        return option.value || '';
                                    }}
                                    renderOption={(option: any) => {
                                        return (
                                            <div
                                                className="hw_sc_suggestion"
                                                dangerouslySetInnerHTML={{
                                                    __html: option.highlighted,
                                                }}
                                            />
                                        );
                                    }}
                                    noOptionsText={'No keyword found'}
                                    onChange={handleChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                e.persist();
                                                clearTimeout(+timerId!);
                                                const id = setTimeout(() => {
                                                    setOrganizationSearch(
                                                        e.target.value,
                                                    );
                                                }, 500);
                                                setTimerId(id);
                                            }}
                                            label="Search by keyword"
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                            </div>
                            <div className="hw_searcharea_input">
                                <Autocomplete
                                    id="city-box-demo"
                                    options={cityOptions}
                                    value={selectedCity}
                                    onChange={handleCityAutoCompleteChange}
                                    getOptionLabel={(option: any) =>
                                        option.value || ''
                                    }
                                    renderOption={(option: any) => {
                                        return (
                                            <div
                                                className="hw_sc_suggestion"
                                                dangerouslySetInnerHTML={{
                                                    __html: option.highlighted,
                                                }}
                                            />
                                        );
                                    }}
                                    noOptionsText={'No city found'}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>,
                                            ) => setCitySearch(e.target.value)}
                                            label="Search by city"
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                            </div>
                            <Button
                                disabled={isChecking}
                                className="g_primary_btn"
                                onClick={goToSearchPage}
                                color="secondary"
                                variant="contained"
                            >
                                Search
                            </Button>
                        </div>
                    </div>
                    <div className="g_row_center g_mt4 g_mb1">
                        <Typography variant="h4">
                            Featured Organizations
                        </Typography>
                    </div>
                    <div className="hw_organization_list">
                        <OrganizationList />
                    </div>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                    >
                        <Button
                            disabled={isChecking}
                            onClick={handleSeeFullList}
                            style={{ textDecoration: 'underline' }}
                        >
                            See Full List &gt;&gt;
                        </Button>
                    </Box>
                    <div className="g_row_center g_mt2">
                        <Typography variant="h5">
                            Connect Your Skills to Those Who Need it the Most
                        </Typography>
                    </div>
                    <div className="g_row_center g_mt2 g_mb1">
                        {!firebase.isAuthenticated() && (
                            <div className="hw_selected_button">
                                <Button
                                    className="g_primary_btn"
                                    fullWidth
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        navigate('/signup');
                                    }}
                                >
                                    Sign Up
                                </Button>
                            </div>
                        )}
                        <div className="hw_selected_button">
                            <Button
                                fullWidth
                                color="default"
                                variant="outlined"
                            >
                                Contact Us
                            </Button>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};
