import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
} from '@material-ui/core';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DatePickerCalenderProps {
    handleOpen?: () => void;
    handleClose: () => void;
    handleChange: (date: Date | null) => void;
    open: boolean;
    selected: Date | null;
    minDate?: Date;
    maxDate?: Date;
}

const DatePickerCalendar: React.FC<DatePickerCalenderProps> = ({
    handleOpen,
    handleClose,
    open,
    selected,
    handleChange,
    minDate,
    maxDate,
}) => {
    return (
        <Dialog
            open={open}
            maxWidth="xs"
            classes={{ paper: 'dialogContainer' }}
        >
            <DialogContent style={{ padding: 0 }}>
                <DatePicker
                    selected={selected}
                    onChange={handleChange}
                    inline
                    showMonthDropdown
                    showYearDropdown
                    dateFormat="dd MMM, yyyy"
                    dropdownMode="select"
                    calendarClassName="react-datepicker-popup"
                    minDate={minDate}
                    maxDate={maxDate}
                />
            </DialogContent>

            <DialogActions className="dialogFooter">
                <Button
                    onClick={handleClose}
                    style={{ color: '#FFC4A3', fontWeight: 'bold' }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleClose}
                    style={{ color: 'white', fontWeight: 'bold' }}
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DatePickerCalendar;
